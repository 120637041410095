import Cookies from "js-cookie";
import useApi from "../../../../Utils/BackendClient";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import commonStyle from "../../CommonStyles.module.css";
import { Translate } from "../../../../Utils/TranslationUtils";
import { formatDate } from "../../../../Utils/Utils";

const Currencies = () => {

    const currenciesList = ["USD", "EUR"];
    const toast = useRef(null);
    const navigate = useNavigate();
    const { addCurrencyRate, fetchCurrenciesList, fetchCurrencyHistory } = useApi();

    const [currencies, setCurrencies] = useState([]);
    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState('center');
    const [reloadData, setReloadData] = useState(true);
    const [historyVisible, setHistoryVisible] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [history, setHistory] = useState(null);
    const [historyTotalRecords, setHistoryTotalRecords] = useState(0);
    const [historyLazyState, setHistoryLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1   
    });

    useEffect(() => {

        const fetchData = async () => {

            const response = await fetchCurrenciesList();

            if(response.status === 200){
                setCurrencies(response.data);
            }
            else{
                toast.current.show({ severity: "error", summary: "Помилка отримання списку валют", detail: "Помилка сервера " + response.status, life: 3000 });
            }
        }

        fetchData();

    }, [reloadData, fetchCurrenciesList]);

    const formik = useFormik({
        initialValues: {
            date: new Date(),
            rate: '',
            currency: null,
        },
        validate: values => {
            const errors = {};

            if (values.rate === null || values.rate === "") {
                errors.rate = "Курс не повинен бути пустим";
            }
            else if (isNaN(Number(values.rate))) {
                errors.rate = 'Курс повинен бути числом';
            }

            if (values.currency === null || values.currency === undefined) {
                errors.currency = "Валюта не повинна бути пустою";
            }

            return errors;
        },
        onSubmit: values => {

            const day = ('0' + formik.values.date.getDate()).slice(-2); // Add leading zero if needed
            const month = ('0' + (formik.values.date.getMonth() + 1)).slice(-2); // Month is zero-based, so add 1
            const year = formik.values.date.getFullYear();

            const formattedDate = `${year}-${month}-${day}`;

            formik.values.date = formattedDate
            formik.values.rate = +formik.values.rate;

            console.log(values);

            // Call to backend
            const addCurrenciesRate = async () => {

                const response = await addCurrencyRate(values);
                //logging to remove later
                console.log(response);

                if (response.status !== 201){ 
                    toast.current.show({ severity: "error", summary: "Помилка додавання курсу", detail: "Помилка сервера " + response.status, life: 3000 });

                    formik.resetForm();
                    setVisible(false);
                    setReloadData(!reloadData);
                }
                else
                {
                    toast.current.show({ severity: 'success', summary: "Успіх", detail: "Курс валюти " + values.currency + " на " + values.date +" додано", life: 3000 });
                    
                    formik.resetForm();
                    setVisible(false);
                    setReloadData(!reloadData);
                    Cookies.remove("USDRate");
                    navigate(0)
                }
            }

            addCurrenciesRate();
        }
    });

    const show = (position) => {
        setPosition(position);
        setVisible(true);
    };

    const ShowExchangeRates = rowData => {
        const route = '/currencies/currencyrates/' + rowData.currency_short;
        navigate(route, { state: { currency_short: rowData.currency_short } });
    }

    const getHistory = async (currency, state) => {

        const response = await fetchCurrencyHistory(currency, state);

        console.log(response);

        if (response.status === 200) {
            setHistory(response.data.results);
            setHistoryTotalRecords(response.data.count);
        }
        else {
            toast.current.show({ severity: "error", summary: "Помилка отримання історії", detail: "Помилка сервера " + response.status, life: 3000 });
        }
    }

    const onHistoryPage = (event) => {
        event.page = event.page + 1;
        getHistory(selectedCurrency, event);

        setHistoryLazyState(event);
    }

    const ShowCurrencyHistory = rowData => {
        setSelectedCurrency(rowData.currency_short);
        getHistory(rowData.currency_short, historyLazyState);
        setHistoryVisible(true);
    }

    const HideDialog = () => {
        setVisible(false);
        formik.resetForm();
    }

    const actionBodyTemplate = (rowData) => {

        return (<div className="flex flex-wrap gap-2">
            <Button
                severity="secondary"
                disabled={rowData.currency_short === "UAH"}
                icon="pi pi-list"
                className={`p-button-rounded p-button-secondary`}
                tooltip={"Архів валюти"}
                tooltipOptions={{ position: "top" }}
                onClick={() => ShowExchangeRates(rowData)}
            />
            <Button
                severity="secondary"
                disabled={rowData.currency_short === "UAH"}
                icon="pi pi-history"
                className={`p-button-rounded p-button-info`}
                tooltip={"Історія змін"}
                tooltipOptions={{ position: "top" }}
                onClick={() => ShowCurrencyHistory(rowData)}
            />
        </div>
        );
    };

    const mainToolbarLeftTemplate = () => {
        return (<h3>Курси валют</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Додати курс валют" severity="success" className={commonStyle.addButton} onClick={() => { show("top") }} />
            </div>
        );
    }

    return (<>
        <Toast ref={toast} />
        <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={currencies} stripedRows rows={10} tableStyle={{ minWidth: '60rem' }} >
                    <Column field="currency_long" header="Валюта" sortable />
                    <Column field="currency_short" header="Скорочення" />
                    <Column field="currency_rate" dataType="number" header="Поточний курс" sortable />
                    <Column field="last_rate_date" dataType="date" header="Остання зміна курсу" sortable />
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
        </div>
        <Dialog header="Додати курс валюти на певну дату"
            visible={visible}
            position={position}
            style={{ width: '35vw', display: 'flex', flexDirection: 'column' }}
            onHide={HideDialog}
            draggable={false}
            resizable={false}
        >
            <form onSubmit={formik.handleSubmit} style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                <div className={commonStyle.dialogFormInputFirst}>
                    <div className="p-inputgroup flex-1 md:w-22rem">
                        <FloatLabel>
                            <Calendar value={formik.values.date} onChange={(e) => { formik.setFieldValue('date', e.target.value) }} showIcon dateFormat="dd/mm/yy" />
                            <label>Дата</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className={commonStyle.dialogFormInput}>
                    <div className="p-inputgroup flex flex-column gap-1 md:w-22rem">
                        <FloatLabel>
                            <InputText
                                name="rate"
                                value={formik.values.rate}
                                onChange={(e) => { formik.setFieldValue('rate', e.target.value) }}
                                className={formik.errors.rate && formik.touched.rate ? 'p-invalid' : ''}
                            />
                            <label>Курс</label>
                        </FloatLabel>
                        {formik.errors.rate && formik.touched.rate && (<small className={commonStyle.errorSmall}>{formik.errors.rate}</small>)}
                    </div>
                </div>
                <div className={commonStyle.dialogFormInput}>
                    <div className="p-inputgroup flex flex-column gap-1 md:w-22rem">
                        <FloatLabel>
                            <Dropdown
                                name="currency"
                                value={formik.values.currency}
                                onChange={(e) => formik.setFieldValue('currency', e.value)}
                                options={currenciesList}
                                showClear
                                className={formik.errors.currency && formik.touched.currency ? 'p-invalid w-full' : 'w-full'}
                            />
                            <label>Валюта</label>
                        </FloatLabel>
                        {formik.errors.currency && formik.touched.currency && (<small className={commonStyle.errorSmall}>{formik.errors.currency}</small>)}
                    </div>
                </div>
                <div className={commonStyle.dialogSubmit}>
                    <Button label="Додати поле" icon="pi pi-check" type="submit" className={commonStyle.addButton} autoFocus />
                </div>
            </form>
        </Dialog>
        <Sidebar visible={historyVisible} position="right" header={<h3>Історія валюти {selectedCurrency}</h3>} onHide={() => setHistoryVisible(false)} className={commonStyle.sidebarTable}>
            <DataTable value={history} emptyMessage="Історії не знайдено"
                lazy paginator onPage={onHistoryPage} first={historyLazyState.first} totalRecords={historyTotalRecords} rows={historyLazyState.rows} rowsPerPageOptions={[10, 20, 50]}>
                <Column field="action_type" header="Тип змін" body={(rowData) => Translate(rowData.action_type)} />
                <Column field="data" header="Курс і час" body={(rowData) => `${rowData.data.rate} (${rowData.data.date})`}/>
                <Column field='made_by' header="Користувач" />
                <Column field="action_time" header="Час змін" body={(rowData) => formatDate(rowData.action_time)}/>
            </DataTable>
        </Sidebar>
    </>
    );
}

export default Currencies;