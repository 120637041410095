import { useFormik } from "formik"
import { addLocale, locale } from "primereact/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import commonStyle from "../../CommonStyles.module.css";
import useGetEnums from "../../../../Utils/EnumsUtils";
import EnumsContext from "../../../../context/enums-context";
import { UaLocale } from "../../../../Utils/TranslationUtils";
import { convertSumWithLatestCurrencyRate, findKeyByValue, formatCurrency, formatShortDate, systemCurrencies } from "../../../../Utils/Utils";
import useApi from "../../../../Utils/BackendClient";
import { Sidebar } from "primereact/sidebar";
import { TabMenu } from "primereact/tabmenu";
import HistoryElement from "../../../UIElements/HistoryElement";

const DistributorReturnDetails = () => {

    const ctx = useContext(EnumsContext);
    const location = useLocation();
    const navigate = useNavigate();
    const toast = useRef(null);
    const { createDistributorReturnAct, deletePaymentFromAct, fetchActPayments, fetchDistributorReturnActDetails, fetchDistributorReturnActHistory, fetchRemainings, updateDistributorReturnAct } = useApi();

    const [isEdit, setIsEdit] = useState(false);
    const [isFinalized, setIsFinalized] = useState(false);
    const [visible, setVisible] = useState(false);
    const [quantitySideBarVisible, setQuantitySidebarVisible] = useState(false);
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);
    const [totaLActSum, setTotalActSum] = useState(0);
    const [loading, setLoading] = useState(true);
    const [remainingProducts, setRemainingProducts] = useState(null);
    const [selectedProduct, setSelectedProduct] =  useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tabShowed, setTabShowed] = useState(0);
    const [actPayments, setActPayments] = useState([]);
    const [actHistory, setActHistory] = useState(null);
    const [historyTotalRecords, setHistoryTotalRecords] = useState(0);
    const [lazyState, setlazyState] = useState(
        {
            first: 0,
            rows: 20,
            page: 1,
            sortField: "created_at",
            sortOrder: 1,
            filters: {
                partner_id: { value: null, matchMode: 'contains' },
            }
        }
    );
    const [historyLazyState, setHistoryLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1   
    });
    const [actData, setActData] = useState({
            cagent: null,
            division: ctx?.divisions[0],
            act_number_autogenerate: true,
            act_number: '',
            act_date: new Date(),
            pay_due_date: new Date(),
            status: "Драфт",
            pay_status: "Не оплачено",
            identified_products: [],
            unidentified_products: [],
    });

    addLocale('ua', UaLocale);
    locale('ua');
    useGetEnums(["divisions", "partners"]);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    const formik = useFormik({
        initialValues: actData,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.cagent === null || values.cagent === undefined) {
                errors.cagent = "Виберіть контрагента";
            }

            if (values.division === null || values.division === undefined) {
                errors.division = "Виберіть підрозділ";
            }

            if (!values.act_number_autogenerate) {
                if (values.act_number === null || values.act_number === "") {
                    errors.act_number = "Введіть номер акту";
                }
            }
            return errors;
        },
        onSubmit: (values) => {
            values.act_date = formatShortDate(values.act_date);
            values.pay_due_date = formatShortDate(values.pay_due_date);
            values.act_number = values.act_number === "" ? null : values.act_number;

            console.log(values);

            const saveReturnAct = async () => {

                const response = isEdit ? await updateDistributorReturnAct(values.id, values) : await createDistributorReturnAct(values);

                console.log(response);

                let toastMessage = {
                    severity: "error", summary: isEdit ? "Помилка редагування" : "Помилка створення",
                    detail: "Акт не " + (isEdit ? "відредаговано. " : " створено. ") + " Код помилки: " + response.status, life: 3000
                };

                const successStatus = isEdit ? 200 : 201;

                if (response.status === successStatus) {
                    toastMessage = { severity: "success", summary: "Успіх " + (isEdit ? "редагування" : "cтворення"), detail: "Акт успішно " + (isEdit ? "відредаговано" : "створено"), life: 3000 }
                }

                navigate("/distributorreturns", { state: { toast: toastMessage } });
            }

            saveReturnAct();
        }
    });

    useEffect(() => {
        if (location.state !== null) {

            setIsEdit(true);

            const fetchData = async () => {

                const response = await fetchDistributorReturnActDetails(location.state.id);

                if (response.status === 200) {
                    console.log(response.data);

                    setIsFinalized(response.data.status !== "PENDING");
                    response.data.act_date = new Date(response.data.act_date);
                    response.data.pay_due_date = new Date(response.data.pay_due_date);

                    response.data.identified_products.forEach(element => {
                        element.product_name = element.product;
                        element.product = element.product_id;
                    });

                    response.data.unidentified_products.forEach(element => {
                        element.product_name = element.product;
                        element.product = element.product_id;
                    });

                    let totalValue = 0
                    if (response.data.identified_products.length > 0) {
                        totalValue = totalValue + response.data.identified_products.reduce((accumulator, element) => accumulator + element.priceUAH, 0);
                    }
                    if (response.data.unidentified_products.length > 0) {
                        totalValue = totalValue + response.data.unidentified_products.reduce((accumulator, element) => accumulator + element.priceUAH * element.quantity, 0);
                    }

                    console.log(response.data);

                    setTotalActSum(totalValue);
                    setActData(response.data);
                }
            }

            fetchData();
        }
    }, [location.state, fetchDistributorReturnActDetails]);

    const DeleteFieldHandler = (rowData, fields, name) => {

        const updatedFields = [...fields];

        const index = fields.findIndex(function (field) {
            return field.id === rowData.id && field.price === rowData.price && field.currency === rowData.currency
        });

        setTotalActSum(totaLActSum - (fields[index].quantity === undefined ? 1 : fields[index].quantity) * +fields[index].priceUAH);

        updatedFields.splice(index, 1);
        formik.setFieldValue(name, updatedFields);
    };

    const ReturnButtonHandler = () => {
        navigate("/distributorreturns");
    }

    const setTableHeader = (tablename) => {
        return (
            <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                <span className="text-xl text-900 font-bold">{tablename}</span>
            </div>
        );
    }

    const TableFooter = (data) => {

        const totalValue = data.reduce((accumulator, element) => accumulator + element.priceUAH * (element.quantity === undefined ? 1 : element.quantity), 0);

        return (
            <div className={commonStyle.footerContainer}>
                <label>Cума: {formatCurrency(totalValue, "UAH")}</label>
            </div>)
    }

    const UnbindPayment = async (paymentId) => {

        const request = {
            act_id: location.state.id,
            payment_id: paymentId
        }

        const response = await deletePaymentFromAct(request);

        console.log(response);
        if(response.status === 204){
            
            const index = actPayments.findIndex(payment => payment.id === paymentId);
            if (index !== -1) {
                // Create a new array with the element removed
                const newPayments = [...actPayments.slice(0, index), ...actPayments.slice(index + 1)];
                console.log(newPayments);
                
                // Set the updated state
                setActPayments(newPayments);
            }

            toast.current.show({ severity: "success", summary: "Платіж відвязано", detail: "Платіж відвязано від акту", life: 3000 });

        }
        else{
            toast.current.show({ severity: "error", summary: "Помилка відвязки платежу", detail: "Платіж не відвязано від акту. Код помилки: " + response.status, life: 3000 });
        }
    }

    const SelectPartner = (id) => {

        formik.setFieldValue("cagent", id);

        setLoading(true);

        const getRemainingsByPartnerId = async () => {

            const lazyStateToPass = lazyState;
            lazyStateToPass.filters.partner_id.value = id;

            const response = await fetchRemainings(lazyStateToPass);

            if (response.status === 200) {
                console.log(response.data.results);
                setRemainingProducts(response.data.results);
                setTotalRecords(response.data.count);
            }
            else {
                setRemainingProducts([]);
            }

            setLoading(false);
        }

        getRemainingsByPartnerId();
    }

    const getActPayments = async (id) => {

        const response = await fetchActPayments(location.state.id);

        if (response.status === 200) {
            setActPayments(response.data.results);
        }
        else {
            toast.current.show({ severity: "error", summary: "Помилка отримання платежів", detail: "Помилка сервера " + response.status, life: 3000 });
        }
    }

    const getActHistory = async (id, state) => {

        const response = await fetchDistributorReturnActHistory(id, state);

        console.log(response);

        if (response.status === 200) {
            setActHistory(response.data.results);
            setHistoryTotalRecords(response.data.count);
        }
        else {
            toast.current.show({ severity: "error", summary: "Помилка отримання історії", detail: "Помилка сервера " + response.status, life: 3000 });
        }
    }

    const items = [
        {
            label: 'Деталі акту повернення',
            icon: 'pi pi-book',
            command: () => {
                setTabShowed(0);
            },
        },
        ...(isFinalized
            ? [
                {
                    label: 'Платежі',
                    icon: 'pi pi-money-bill',
                    command: () => {
                        setTabShowed(1);
                        getActPayments(location.state.id);
                    },
                },
            ]
            : []),
        ...(isEdit
            ? [
                {
                    label: 'Історія',
                    icon: 'pi pi-history',
                    command: () => {
                        setTabShowed(2);
                        getActHistory(location.state.id, historyLazyState);
                    },
                },
            ]
            : []),
    ];

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onHistoryPage = (event) => {
        event.page = event.page + 1;
        getActHistory(location.state.id, event);

        setHistoryLazyState(event);
    }

    const AddProductHandler = () => {
        if(isEdit)
        {
            SelectPartner(formik.values.cagent);
        }
        
        setVisible(true);
    }

    const AddProductToTableHandler = (rowData) => {

        if (rowData.identifier === undefined && rowData.total_quantity > 1) {
            setQuantitySidebarVisible(true);
            setSelectedProduct(rowData);
        }
        else {
            AddProductToAct(rowData);
        }
    }

    const AddProductToAct = (rowData) => {

        if (rowData.identifier !== undefined && rowData.identifier !== null) {
            if (!formik.values.identified_products.some(obj => obj.identifier === rowData.identifier)) {
                
                let newobject = formik.values.identified_products;
                rowData.remaining_id = rowData.id;
                rowData.priceUAH = convertSumWithLatestCurrencyRate(rowData.currency, rowData.price);
                setTotalActSum(totaLActSum + rowData.priceUAH);
                newobject = [...newobject, rowData];
                formik.setFieldValue("identified_products", newobject);
            }
            else {
                toast.current.show({ severity: "error", summary: "Помилка додавання", detail: `Товар ${rowData.product_name} (ID:${rowData.identifier}) уже додано`, life: 3000 });
            }
        }
        else {

            let newobject = formik.values.unidentified_products;
            const index = formik.values.unidentified_products.findIndex(x => x.remaining_id === rowData.id);

            if(index >= 0 && newobject[index].price === rowData.price && newobject[index].currency === rowData.currency){
                newobject[index].quantity = +newobject[index].quantity + +rowData.total_quantity;
                newobject[index].priceUAH = convertSumWithLatestCurrencyRate(newobject.currency, newobject[index].price);
                setTotalActSum(totaLActSum + (+newobject[index].priceUAH * +rowData.total_quantity));
            }
            else{
                console.log(rowData.total_quantity);
                rowData.quantity = rowData.total_quantity;
                rowData.remaining_id = rowData.id;
                delete rowData.id;
                rowData.priceUAH = convertSumWithLatestCurrencyRate(rowData.currency, rowData.price)
                setTotalActSum(totaLActSum + (+rowData.priceUAH * +rowData.quantity));

                console.log(rowData);
                newobject = [...newobject, rowData]
            }
;
            formik.setFieldValue("unidentified_products", newobject);
        }

        setQuantitySidebarVisible(false);
    }

    const setSelectedProductQuantity = (amount) => {

        setSelectedProduct(prevProduct => ({
            ...prevProduct,
            total_quantity: amount
        }));
    }

    const setSelectedProductPrice = (insertedPrice) => {

        setSelectedProduct(prevProduct => ({
            ...prevProduct,
            price: +insertedPrice
        }));
    }

    const setSelectedProductCurrency = (insertedCurrency) => {

        setSelectedProduct(prevProduct => ({
            ...prevProduct,
            currency: insertedCurrency
        }));
    }

    const mainToolbarLeftTemplate = () => {

        let name = "Створення нового акту повернення постачальнику";

        if (isEdit) {
            name = "Редагування акту повернення постачальнику"

            if (isFinalized) {
                name = "Інформація про акт повернення постачальнику"
            }
        }
        return (<h3>{name}</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (<>
            <div className="flex flex-wrap gap-2">
                {!isFinalized && <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />}
                <Button label="Вийти" severity="secondary" className={commonStyle.closeButton} type="button" onClick={ReturnButtonHandler} />
            </div>
        </>);
    }

    const contentToolbarRightTemplate = () => {
        return (<>
            <div className="flex flex-wrap gap-2">
                <Button label="+Додати товар" severity="success" className={commonStyle.addButton} type="button" onClick={AddProductHandler} />
            </div>
        </>);
    }

    const tableActionBodyTemplate = (rowData) => {

        var isId = Object.hasOwn(rowData, 'identifier');

        return (<div className="flex flex-wrap gap-2">
            {!isFinalized && <Button
                icon="pi pi-trash"
                type="button"
                className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
                tooltip="Видалити з таблиці"
                tooltipOptions={{ showOnDisabled: true }}
                onClick={() => DeleteFieldHandler(rowData, isId ? formik.values.identified_products : formik.values.unidentified_products, isId ? "identified_products" : "unidentified_products")}
            />}
        </div>
        );
    };

    const paymentActionBodyTemplate = (rowData) => {
        return (<Button
            icon="pi pi-times"
            type="button"
            className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
            tooltip="Відв'язати платіж"
            tooltipOptions={{ position: "top" }}
            onClick={() => UnbindPayment(rowData.id)}
        />
        );
    }

    const actionBodyTemplate = (rowData) => {

        const allItemsAreBooked = rowData.total_quantity === rowData.booked;

        return (<div className="flex flex-wrap gap-2">
            <Button
                icon="pi pi-plus"
                type="button"
                className={`p-button-rounded ${allItemsAreBooked ? commonStyle.closeButton : commonStyle.addButton}`}
                disabled={allItemsAreBooked}
                tooltip={allItemsAreBooked ? "Товар використано в акті" : "Додати"}
                tooltipOptions={{ showOnDisabled: true, position: "left" }}
                onClick={() => AddProductToTableHandler(rowData)}
            />
        </div>
        );
    };

    return (<form onSubmit={formik.handleSubmit}>
        <Toast ref={toast} />
        <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate} />
        <TabMenu model={items} />
        {tabShowed === 0 && <div className="grid">
            <div className={`${commonStyle.leftMenu} col-3`}>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <Dropdown value={ctx.partners[formik.values.cagent]}
                                onChange={(e) => SelectPartner(findKeyByValue(ctx.partners, e.value))}
                                options={Object.values(ctx.partners)}
                                disabled={isFinalized || formik.values.identified_products.length > 0 || formik.values.unidentified_products.length > 0}
                                showClear
                                className={formik.errors.cagent && formik.touched.cagent ? 'p-invalid w-full' : "w-full"} />
                            <label>Контрагент</label>
                        </FloatLabel>
                        {formik.errors.cagent && formik.touched.cagent && (<small className={commonStyle.errorSmall}>{formik.errors.cagent}</small>)}
                    </div>
                </div>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <Dropdown value={ctx.divisions[formik.values.division]}
                                onChange={(e) => formik.setFieldValue('division', findKeyByValue(ctx.divisions, e.value))}
                                options={Object.values(ctx.divisions)}
                                disabled={isFinalized}
                                showClear
                                className={formik.errors.division && formik.touched.division ? 'p-invalid w-full' : "w-full"} />
                            <label>Підрозділ</label>
                        </FloatLabel>
                        {formik.errors.division && formik.touched.division && (<small className={commonStyle.errorSmall}>{formik.errors.division}</small>)}
                    </div>
                </div>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-1">
                        <FloatLabel>
                            <Calendar value={formik.values.act_date}
                                onChange={(e) => { formik.setFieldValue('act_date', e.target.value) }}
                                disabled={isFinalized}
                                showIcon />
                            <label>Дата акту</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-1">
                        <FloatLabel>
                            <Calendar value={formik.values.pay_due_date}
                                onChange={(e) => { formik.setFieldValue('pay_due_date', e.target.value) }}
                                disabled={isFinalized}
                                showIcon />
                            <label>Очікувана дата оплати</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className={commonStyle.checkBoxInput}>
                    <div className={`${commonStyle.checkboxStyle} p-inputgroup flex-1 md:w-22rem"`}>
                        <Checkbox
                            name="act_number_autogenerate"
                            onChange={(e) => { formik.setFieldValue('act_number_autogenerate', !formik.values.act_number_autogenerate) }}
                            checked={formik.values.act_number_autogenerate}
                        />
                        <label>Автоматично генерувати номер акту</label>
                    </div>
                </div>
                {!formik.values.act_number_autogenerate && <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText value={formik.values.act_number}
                                onChange={(e) => { formik.setFieldValue('act_number', e.target.value) }}
                                disabled={isFinalized}
                                className={formik.errors.act_number && formik.touched.act_number ? 'p-invalid w-full' : "w-full"} />
                            <label>Номер акту</label>
                        </FloatLabel>
                        {formik.errors.act_number && formik.touched.act_number && (<small className={commonStyle.errorSmall}>{formik.errors.act_number}</small>)}
                    </div>
                </div>}
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText value={formik.values.status === "FINALIZED" ? "Фіналізований" : "Драфт"}
                                disabled
                                className="w-full" />
                            <label>Статус</label>
                        </FloatLabel>
                    </div>
                </div>
                {isFinalized && <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText value={ctx.pay_statuses[formik.values.pay_status]}
                                disabled
                                className="w-full" />
                            <label>Статус оплати</label>
                        </FloatLabel>
                    </div>
                </div>}
            </div>
            <div className="col-9">
                {!isFinalized && <Toolbar className="mb-4" end={contentToolbarRightTemplate} />}
                {(formik.values.identified_products.length > 0) && <DataTable value={formik.values.identified_products} header={setTableHeader("Товари індивідуального обліку")}
                    footer={() => TableFooter(formik.values.identified_products)} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="product_name" header="Продукт" style={{ width: '25%' }} />
                    <Column field="note" header="Примітка" style={{ width: '20%' }} />
                    <Column field="identifier" header="Ідентифікатор" style={{ width: '15%' }} />
                    <Column field="price" header="Ціна в валюті розрахунку" body={rowData => formatCurrency(rowData.price, rowData.currency)} style={{ width: '10%' }} />
                    <Column field="priceUAH" header="Ціна, грн" body={rowData => formatCurrency(rowData.priceUAH, "UAH")} style={{ width: '10%' }} />
                    <Column field="action" body={tableActionBodyTemplate} style={{ width: '20%' }} />
                </DataTable>}
                {(formik.values.unidentified_products.length > 0) && <DataTable value={formik.values.unidentified_products} header={setTableHeader("Товари поштучного обліку")}
                    footer={() => TableFooter(formik.values.unidentified_products)} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="product_name" header="Продукт" style={{ width: '25%' }}></Column>
                    <Column field="note" header="Примітка" style={{ width: '20%' }} />
                    <Column field="quantity" header="Кількість" style={{ width: '15%' }}></Column>
                    <Column field="price" header="Ціна в валюті розрахунку" body={rowData => formatCurrency(rowData.price, rowData.currency)} style={{ width: '10%' }}></Column>
                    <Column field="priceUAH" header="Ціна в гривні" body={(rowData => formatCurrency(rowData.priceUAH, "UAH"))} style={{ width: '10%' }}></Column>
                    <Column field="action" body={tableActionBodyTemplate} style={{ width: '20%' }} />
                </DataTable>}
                {(formik.values.unidentified_products.length > 0 || formik.values.identified_products.length > 0) && <div className={commonStyle.footerContainer}>
                    <h3>Загальна сума акту: {formatCurrency(totaLActSum, "UAH")}</h3>
                </div>}
            </div>
        </div>}
        {(tabShowed === 1 && isFinalized) &&
            <DataTable value={actPayments} emptyMessage="Платежів по акту не знайдено">
                <Column field="id" header="ID" style={{ width: '10%' }} />
                <Column field="date" header="Дата оплати" style={{ width: '20%' }} />
                <Column field="cagent_name" header="Контрагент" body={(rowData) => rowData.cagent_name === null ? "(Клієнта не вказано)" : rowData.cagent_name} style={{ width: '20%' }} />
                <Column field="distribution_sum" header="Сума" body={(rowdata) => formatCurrency(rowdata.distribution_sum, rowdata.currency)} style={{ width: '20%' }} />
                <Column field="distribution_sum_UAH" header="Сума, грн" body={(rowdata) => formatCurrency(rowdata.distribution_sum_UAH, "UAH")} style={{ width: '20%' }} />
                <Column field="action" body={paymentActionBodyTemplate} style={{ width: '10%' }} />
            </DataTable>}
        {tabShowed === 2 && <HistoryElement data={actHistory} lazyState={historyLazyState} totalRecords={historyTotalRecords} onHistoryPage={onHistoryPage} isAct={true}/>}
        <Sidebar visible={visible} position="right" className={`${commonStyle.shortSideBar} ${quantitySideBarVisible && isLargeScreen ? commonStyle.moveLeft : ''}`} onHide={() => setVisible(false)}>
            <div className={commonStyle.sideBarInput}>
                {formik.values.cagent === null && <label>Виберіть постачальника для вибору товарів</label>}
                <div className="p-inputgroup flex-column mt-4">
                    <FloatLabel>
                        <Dropdown
                            name="partners"
                            value={ctx.partners[formik.values.cagent]}
                            onChange={(e) => SelectPartner(findKeyByValue(ctx.partners, e.value))}
                            options={Object.values(ctx.partners)}
                            disabled={formik.values.identified_products.length > 0 || formik.values.unidentified_products.length > 0}
                            className="w-full"
                        />
                        <label>Постачальник</label>
                    </FloatLabel>
                </div>
                {formik.values.cagent !== null && <DataTable value={remainingProducts} header="Товари" lazy className="mt-3" emptyMessage="Товари в залишках знайдено"
                    rows={lazyState.rows} paginator onPage={onPage} first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[20, 50]}>
                    <Column field="product_name" style={{ width: '90%' }} />
                    <Column field="action" body={actionBodyTemplate} style={{ width: '10%' }} />
                </DataTable>}
            </div>
        </Sidebar>
        <Sidebar visible={quantitySideBarVisible} position="right" className={`${commonStyle.sidebarmoved} ${commonStyle.shortSideBar} ${!isLargeScreen && 'sidebar-mobile'}`} onHide={() => setQuantitySidebarVisible(false)}>
            <div className={commonStyle.formInput}>
                <div className="p-inputgroup flex-column">
                    <FloatLabel>
                        <InputText value={selectedProduct?.total_quantity}
                            onChange={(e) => setSelectedProductQuantity(e.target.value)}
                            className="w-full" />
                        <label>Кількість</label>
                    </FloatLabel>
                </div>
                <div className="p-inputgroup flex-1 mt-5">
                    <span className={`${commonStyle.currencySpan} p-inputgroup-addon`}>
                        <Dropdown
                            className={`${commonStyle.currencyDropDown} w-full`}
                            value={selectedProduct?.currency}
                            onChange={e => setSelectedProductCurrency(e.value)}
                            options={systemCurrencies}
                        />
                    </span>
                    <FloatLabel>
                        <InputText
                            onChange={e => setSelectedProductPrice(e.target.value)}
                            name="amount"
                            value={selectedProduct?.price}
                            className={formik.errors.price && formik.touched.price ? 'p-invalid' : ''}
                        />
                        <label>Ціна</label>
                    </FloatLabel>
                    {formik.errors.price && formik.touched.price && (<small className={commonStyle.errorSmall}>{formik.errors.price}</small>)}
                </div>
            </div>
            <div>
                <Button label="Додати" severity="success" type="button" onClick={() => { AddProductToAct(selectedProduct) }} className={`${commonStyle.sideBarButton} ${commonStyle.addButton}`} autoFocus />
            </div>
        </Sidebar>
    </form>)
}

export default DistributorReturnDetails