import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import useApi from "../../../../Utils/BackendClient";

import useGetEnums from "../../../../Utils/EnumsUtils";
import EnumsContext from "../../../../context/enums-context";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import classes from './NewUser.module.css';
import commonStyle from "../../CommonStyles.module.css";
import randomUserPicture from "../../../../images/Random_user_pic.png";
import { Column } from "primereact/column";
import { TabMenu } from "primereact/tabmenu";
import { Translate } from "../../../../Utils/TranslationUtils";
import { formatDate } from "../../../../Utils/Utils";
import HistoryElement from "../../../UIElements/HistoryElement";


const NewUser = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const toast = useRef(null);
    const ctx = useContext(EnumsContext);
    const { createUser, fetchUserData, fetchUserActivity, fetchUserHistory, fetchUserLogins, fetchUserRoles, updateUser } = useApi();

    const [isEdit, setIsEdit] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [tabShowed, setTabShowed] = useState(0);
    const [history, setHistory] = useState(null);
    const [historyTotalRecords, setHistoryTotalRecords] = useState(0);
    const [logins, setLogins] = useState(null);
    const [loginsTotalRecords, setLoginsTotalRecords] = useState(0);
    const [activity, setActivity] = useState(null);
    const [activityTotalRecords, setActivityTotalRecords] = useState(0);
    const [historyLazyState, setHistoryLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1   
    });
    const [loginsLazyState, setLoginsLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1   
    });
    const [activityLazyState, setActivityLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1   
    });
    const [userDetails, setUserDetails] = useState({
        img: randomUserPicture,
        username: '',
        password: '',
        first_name: '',
        last_name: '',
        division: {
            division: null,
            employee_position: '',
        },
        email: '',
        admin_panel_access: false,
        cash_app_access: false,
        roles: []
    });

    useGetEnums(["divisions"]);

    useEffect(() => {
        if (location.state !== null) {

            setIsEdit(true);

            const fetchData = async () => {

                const response = await fetchUserData(location.state.id);
                if (response.status === 200) {

                    setUserDetails({
                        img: response.data.logo === "" ? randomUserPicture : response.data.logo,
                        username: response.data.username,
                        password: '',
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        division: {
                            division: response.data.division,
                            employee_position: response.data.employee_position,
                        },
                        email: response.data.email,
                        is_active: response.data.is_active,
                        admin_panel_access: response.data.admin_panel_access,
                        cash_app_access: response.data.cash_app_access,
                        roles: response.data.roles
                    });
                }
                else {
                    toast.current.show({ severity: "error", summary: "Помилка", detail: "Помилка отримання даних користувача", life: 2000 })
                }
            }

            fetchData();
        }
    }, [location.state, fetchUserData]);

    const ReturnButtonHandler = () => {
        navigate("/users");
    }

    const formik = useFormik({
        initialValues: userDetails,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.username === null || values.username === "") {
                errors.username = "Логін не повинен бути пустим"
            }

            if ((values.password === null || values.password === "") && location.state === null) {
                errors.password = "Пароль не повинен бути пустим"
            }

            if (values.division.division === null || values.division.division === "") {
                errors.division = "Виберіть підрозділ";
            }

            return errors
        },
        onSubmit: (values) => {

            values.division.division = Number(Object.keys(ctx.divisions).find(key => ctx.divisions[key] === values.division.division));

            if (isEdit) {

                const { password, division, ...rest } = values;

                values = {
                    division,
                    ...rest,
                };
            }

            const callBackEnd = async () => {

                const response = isEdit ? await updateUser(location.state.id, values) : await createUser(values);
                let toastMessage = {};

                if (response.status === 200) {

                    if (isEdit) {
                        toastMessage = { severity: "success", summary: "Успіх редагування", detail: "Користувача успішно відредаговано", life: 3000 }
                    }
                    else {
                        toastMessage = { severity: "success", summary: "Успіх створення", detail: "Користувача успішно створено", life: 3000 }
                    }
                }
                else {

                    if (isEdit) {
                        toastMessage = { severity: "error", summary: "Помилка редагування", detail: "Користувача не відредаговано", life: 3000 }
                    }
                    else {
                        toastMessage = { severity: "success", summary: "Помилка створення", detail: "Користувача не створено", life: 3000 }
                    }
                }

                navigate("/users", { state: { toast: toastMessage } });
            }

            callBackEnd();
        }
    });

    const getUserRoles =  async () => {
        const response = await fetchUserRoles();
        console.log(response);

        if (response.status === 200) {

            const dropdownOptions = response.data.results.map(role => ({
                label: role.name,
                value: role
            }));
            setUserRoles(dropdownOptions);
        }
        else {
            toast.current.show({ severity: "error", summary: "Помилка", detail: "Помилка отримання списку ролей", life: 2000 })
        }
    };

    const AddUserRole = () => {
        if (selectedRole !== null) {
            const userRoles = formik.values.roles;
            console.log(formik.values);

            if (formik.values.roles.length === 0 || !userRoles.some(role => role.id === selectedRole.id)) {
                userRoles.push(selectedRole);
                formik.setFieldValue("roles", userRoles);
                setSelectedRole(null);
            }
            else {
                toast.current.show({ severity: "error", summary: "Помилка додавання ролі", detail: "Користувач уже має таку роль", life: 2000 })
                setSelectedRole(null);
            }
        }
        else {
            toast.current.show({ severity: "error", summary: "Помилка додавання ролі", detail: "Виберіть роль зі списку", life: 2000 })
        }
    }

    const DeleteRoleHandler = (id) => {
        const userRoles = formik.values.roles;
        const index = userRoles.findIndex(role => role.id === id);
        userRoles.splice(index, 1);
        formik.setFieldValue("roles", userRoles);
    }

    const getUserActivity = async (id, state) => {

        const response = await fetchUserActivity(id, state);

        if (response.status === 200) {
            setActivity(response.data.results);
            setActivityTotalRecords(response.data.count);
        }
        else {
            toast.current.show({ severity: "error", summary: "Помилка отримання історії", detail: "Помилка сервера " + response.status, life: 3000 });
        }
    }

    const getUserHistory = async (id, state) => {

        const response = await fetchUserHistory(id, state);

        if (response.status === 200) {
            setHistory(response.data.results);
            setHistoryTotalRecords(response.data.count);
        }
        else {
            toast.current.show({ severity: "error", summary: "Помилка отримання історії", detail: "Помилка сервера " + response.status, life: 3000 });
        }
    }

    const getUserLogins = async (id, state) => {

        const response = await fetchUserLogins(id, state);

        console.log(response.data);

        if (response.status === 200) {
            setLogins(response.data.results);
            setLoginsTotalRecords(response.data.count);
        }
        else {
            toast.current.show({ severity: "error", summary: "Помилка отримання історії входів", detail: "Помилка сервера " + response.status, life: 3000 });
        }
    }

    const onHistoryPage = (event) => {
        event.page = event.page + 1;
        getUserHistory(location.state.id, event);

        setHistoryLazyState(event);
    }

    const onLoginsPage = (event) => {
        event.page = event.page + 1;
        getUserLogins(location.state.id, event);

        setLoginsLazyState(event);
    }

    const onActivityPage = (event) => {
        event.page = event.page + 1;
        getUserActivity(location.state.id, event);

        setActivityLazyState(event);
    }

    const items = [
        {
            label: 'Дані користувача',
            icon: 'pi pi-book',
            command: () => {
                setTabShowed(0);
            },
        },
        ...(isEdit
            ? [
                {
                    label: 'Доступи користувача',
                    icon: 'pi pi-money-bill',
                    command: () => {
                        setTabShowed(1);
                        getUserRoles(location.state.id);
                    },
                },
            ]
            : []),
        ...(isEdit
            ? [
                {
                    label: 'Історія змін',
                    icon: 'pi pi-history',
                    command: () => {
                        setTabShowed(2);
                        getUserHistory(location.state.id, historyLazyState);
                    },
                },
            ]
            : []),
        ...(isEdit
            ? [
                {
                    label: 'Історія входів',
                    icon: 'pi pi-sign-in',
                    command: () => {
                        setTabShowed(3);
                        getUserLogins(location.state.id, loginsLazyState);
                    },
                },
            ]
            : []),
        ...(isEdit
            ? [
                {
                    label: 'Активність користувача',
                    icon: 'pi pi-user-edit',
                    command: () => {
                        setTabShowed(4);
                        getUserActivity(location.state.id, loginsLazyState);
                    },
                },
            ]
            : []),
    ];

    const mainToolbarLeftTemplate = () => {
        if(isEdit){
            return (<h3>Редагування користувача "{userDetails.last_name} {userDetails.first_name}"</h3>);  
        }
        return (<h3>Створення нового користувача</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />
                <Button label="Вийти" severity="secondary" type="button" className={commonStyle.closeButton} onClick={ReturnButtonHandler} />
            </div>
        );
    }

    const actionBodyTemplate = rowData => {
        return (<div className="flex flex-wrap gap-2">
            <Button
                icon="pi pi-trash"
                className={`p-button-rounded p-button-danger ${commonStyle.deleteButtonNoBackground}`}
                tooltip="Видалити"
                type="button"
                onClick={() => DeleteRoleHandler(rowData.id)}
            />
        </div>
        );
    }

    return (<form onSubmit={formik.handleSubmit}>
        <Toast ref={toast} />
        <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
        <TabMenu model={items} />

        {tabShowed === 0 && <div className="grid mt-3">
            <div className="md:col-3 col-12">
                <div className={classes.portraitdiv}>
                    <img className={classes.portrait} alt={formik.values.user} src={formik.values.img} style={{ borderRadius: "50%", display: 'block', margin: 'auto' }} />
                </div>
            </div>
            <div className="md:col-3 col-12">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText
                            value={formik.values.username}
                            onChange={(e) => { formik.setFieldValue('username', e.target.value) }}
                            className={formik.errors.username && formik.touched.username ? 'p-invalid' : ''}
                        />
                        <label>Логін</label>
                    </FloatLabel>
                    {formik.errors.username && formik.touched.username && (<small className={commonStyle.errorSmall}>{formik.errors.username}</small>)}
                </div>
                {location.state === null && <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText
                            value={formik.values.password}
                            onChange={(e) => { formik.setFieldValue('password', e.target.value) }}
                            className={formik.errors.password && formik.touched.password ? 'p-invalid' : ''}
                        />
                        <label>Пароль</label>
                    </FloatLabel>
                    {formik.errors.password && formik.touched.password && (<small className={commonStyle.errorSmall}>{formik.errors.password}</small>)}
                </div>}
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText value={formik.values.division.employee_position} onChange={(e) => { formik.setFieldValue('division.employee_position', e.target.value) }} />
                        <label>Посада</label>
                    </FloatLabel>
                </div>
            </div>
            <div className="md:col-3 col-12">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText value={formik.values.last_name} onChange={(e) => { formik.setFieldValue('last_name', e.target.value) }} />
                        <label>Прізвище користувача</label>
                    </FloatLabel>
                </div>
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText value={formik.values.email} onChange={(e) => { formik.setFieldValue('email', e.target.value) }} />
                        <label>Email</label>
                    </FloatLabel>
                </div>
            </div>
            <div className="md:col-3 col-12">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText value={formik.values.first_name} onChange={(e) => { formik.setFieldValue('first_name', e.target.value) }} />
                        <label>Імя користувача</label>
                    </FloatLabel>
                </div>
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <Dropdown
                            value={formik.values.division.division}
                            onChange={(e) => formik.setFieldValue('division.division', e.value)}
                            options={Object.values(ctx.divisions)}
                            showClear
                            className={formik.errors.division && formik.touched.division?.division ? 'p-invalid' : ''} />
                        <label>Підрозділ</label>
                    </FloatLabel>
                    {formik.errors.division && formik.touched.division?.division && (<small className={commonStyle.errorSmall}>{formik.errors.division}</small>)}
                </div>
            </div>
        </div>}
        {tabShowed === 1 && <div className="grid">
            <div className="lg:col-2 col-5">
                <div className={`ml-3 ${commonStyle.radioButtonBlock}`}>
                    <div className={commonStyle.accountingRadio}>
                        <Checkbox
                            value="admin_panel_access"
                            checked={formik.values.admin_panel_access}
                            onChange={(e) => { formik.setFieldValue('admin_panel_access', !formik.values.admin_panel_access) }}
                        />
                        <label className="ml-2">Доступ до CRM</label>
                    </div>
                    <div className={commonStyle.accountingRadio}>
                        <Checkbox
                            value="cash_app_access"
                            checked={formik.values.cash_app_access}
                            onChange={(e) => { formik.setFieldValue('cash_app_access', !formik.values.cash_app_access) }}
                        />
                        <label className="ml-2">Доступ до Каси</label>
                    </div>
                    {formik.errors.WrongContrAgentType && (formik.touched.is_client || formik.touched.is_partner) && (<small className={commonStyle.errorSmall}>{formik.errors.WrongContrAgentType}</small>)}
                </div>
            </div>
            <div className="lg:col-10 col-7">
                <DataTable value={formik.values.roles} header="Ролі користувача" emptyMessage="Користувачу не призначено ролі" className="mt-3">
                    <Column field="name" style={{ width: '80%' }} headerStyle={{ display: 'none' }}></Column>
                    <Column field="action" body={actionBodyTemplate} headerStyle={{ display: 'none' }} style={{ width: '20%' }} />
                </DataTable>
                <Dropdown
                    value={selectedRole}
                    onChange={(e) => setSelectedRole(e.value)}
                    options={userRoles}
                    style={{ minWidth: '15rem' }}
                />
                <Button icon="pi pi-user" label="Додати роль користувачу" severity="success" className={`${commonStyle.addButton} ${commonStyle.addButtonNav}`} onClick={() => AddUserRole()} type="button" />
            </div>
        </div>}
        {tabShowed === 2 && <HistoryElement data={history} lazyState={historyLazyState} totalRecords={historyTotalRecords} onHistoryPage={onHistoryPage} isAct={false}/>}
        {tabShowed === 3 && <DataTable value={logins} emptyMessage="Журнал входів пустий"
        lazy paginator onPage={onLoginsPage} first={loginsLazyState.first} totalRecords={loginsTotalRecords} rows={loginsLazyState.rows} rowsPerPageOptions={[10, 20, 50]}>
            <Column field="application" header="Додаток" body={(rowData) => Translate(rowData.application)} />
            <Column field='action_time' header="Час логіну" body={(rowData) => formatDate(rowData.action_time)} />
            <Column field="ip_address" header="Ip адреса" />
        </DataTable>}
        {tabShowed === 4 && <DataTable value={activity} emptyMessage="Активності користувача не знайдено"
        lazy paginator onPage={onActivityPage} first={activityLazyState.first} totalRecords={activityTotalRecords} rows={activityLazyState.rows} rowsPerPageOptions={[10, 20, 50]}>
            <Column field="action_type" header="Тип змін" body={(rowData) => Translate(rowData.action_type)}/>
            <Column field='action_time' header="Час змін" body={(rowData) => formatDate(rowData.action_time)}/>
            <Column field="content_type" header="Сутність" body={(rowData) => Translate(rowData.content_type)}/>
            <Column field="object_id" header="ID сутності" />
        </DataTable>}
    </form>);
}

export default NewUser;