
import { buildGroupHierarchy, findGroupCode, statusList, systemCurrencies } from "../../../../Utils/Utils";
import HistoryElement from "../../../UIElements/HistoryElement";
import useApi from "../../../../Utils/BackendClient";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { TabMenu } from "primereact/tabmenu";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { TreeSelect } from "primereact/treeselect";

import commonStyle from "../../CommonStyles.module.css";

const ServiceCreation = () => {

    const toast = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { createService, fetchServicesExtended, fetchServiceDetails, fetchServiceGroupDetails, fetchServiceHistory, updateService } = useApi();

    const [customfields, setCustomFields] = useState(null);
    const [sortedGroups, setSortedGroups] = useState([]);
    const [serviceHistory, setServiceHistory] = useState([]);
    const [historyTotalRecords, setHistoryTotalRecords] = useState(0);
    const [tabShowed, setTabShowed] = useState(0);
    const [service, setService] = useState({
        name: '',
        service_group: null,
        service_article: '',
        price: '',
        currency: "UAH",
        notes: '',
        is_active: "Активний",
        service_fields_values: []
    });
    const [historyLazyState, setHistoryLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1   
    });

    useEffect(() => {

        const getGroupsExtended = async () => {

            const response = await fetchServicesExtended();

            if (response.status === 200) {

                const groups = buildGroupHierarchy(response.data);
                setSortedGroups(groups);

                if (location.state !== null) {
                    console.log(location.state);
                    const fetchData = async () => {

                        const response = await fetchServiceDetails(location.state.id);

                        if (response.status === 200) {
                            response.data.service_group = findGroupCode(groups, response.data.service_group);
                            response.data.is_active = response.data.is_active ? "Активний" : "Деактивований";

                            let fields = [];
                            let serviceFields = {};

                            response.data.service_fields_values.forEach(element => {
                                const field = {
                                    id: element.service_group_field,
                                    field_id: element.id,
                                    name: element.name,
                                    field_type: element.field_type
                                };

                                fields = [...fields, field];
                                serviceFields[element.service_group_field] = element.value;
                            });

                            response.data.service_fields_values = serviceFields;

                            setService(response.data);
                            setCustomFields(fields);
                        }
                    };

                    fetchData();
                }
            }
        }

        getGroupsExtended();

    }, [location.state, fetchServiceDetails, fetchServicesExtended]);

    const DropdownChangeHandler = async (e) => {

        let service_group_code = e.value;
        
        let serviceGroupId = service_group_code;

        if(service_group_code !== null && service_group_code !== undefined)
        {
            if (service_group_code.toString().includes('-')) {
                var keys = service_group_code.split('-');
                serviceGroupId = keys[(keys.length - 1)];
            }
        }
        else{
            service_group_code = null;
        }

        formik.setFieldValue('service_group', service_group_code);

        if (serviceGroupId != null) {

            const fetchData = async () => {

                const response = await fetchServiceGroupDetails(serviceGroupId);

                if (response.status === 200) {
                    const fields = response.data.fields;

                    setCustomFields(fields);

                    console.log(fields);
                    console.log(customfields);

                    const transformedObject = fields.reduce((acc, field) => {
                        acc[field.id] = "";
                        return acc;
                    }, {});

                    formik.setFieldValue('service_fields_values', transformedObject);
                }
            }

            fetchData();
        }
        else {
            setCustomFields(null);
        }
    };

    const formik = useFormik({
        initialValues: service,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.name === null || values.name === "") {
                errors.name = "Назва послуги не повинна бути пустою";
            }

            if (values.service_group === null || values.service_group === undefined) {
                errors.service_group = "Виберіть групу послуг";
            }

            if (values.price === null || values.price === "") {
                errors.price = "Введіть ціну послуги";
            }

            return errors;
        },
        onSubmit: (values) => {

            if (values.service_group.toString().includes('-')) {
                var keys = values.service_group.split('-');
                values.service_group = keys[(keys.length - 1)];
            }

            values.is_active = values.is_active === "Активний" ? true : false;

            let fields = [];

            Object.entries(values.service_fields_values).forEach(([key, value]) => {
                const field = {
                    service_group_field: key,
                    value: value,
                }

                if (location.state != null) {
                    const element = customfields.find(element => element.id === Number(key));
                    field.id = element.field_id;
                }

                fields = [
                    ...fields,
                    field
                ]

            });

            values.service_fields_values = fields;

            const fetchData = async () => {

                const response = location.state === null ? await createService(values) : await updateService(values.id, values);

                const successStatus = location.state ? 200 : 201;

                let toastMessage = {
                    severity: "error", summary: location.state ? "Помилка редагування" : "Помилка створення",
                    detail: "Товар не " + (location.state ? "відредаговано. " : " створено. ") + "Помилка сервера " + response.status, life: 3000
                };

                if (response.status === successStatus) {
                    toastMessage = location.state === null ? { severity: "success", summary: "Успіх створення", detail: "Сервіс " + values.product_name + " успішно створено", life: 3000 } :
                        { severity: "success", summary: "Успіх редагування", detail: "Сервіс " + values.product_name + " успішно відредаговано", life: 3000 }
                }

                navigate("/services", { state: { toast: toastMessage } });
            }

            fetchData();
        }
    });

    const getServiceHistory = async (id, state) => {
        
        const response = await fetchServiceHistory(id, state);

        console.log(response);

        if(response.status === 200){
            setServiceHistory(response.data.results);
            setHistoryTotalRecords(response.data.count);
        }
        else{
            toast.current.show({ severity: "error", summary: "Помилка отримання історії", detail: "Помилка сервера " + response.status, life: 3000 });
        }
    }

    const onHistoryPage = (event) => {
        event.page = event.page + 1;
        getServiceHistory(formik.values.id, event);

        setHistoryLazyState(event);
    }

    const items = [
        {
            label: 'Налаштуванна полів',
            icon: 'pi pi-book',
            command: () => {
                setTabShowed(0);
            },
        },
        {
            label: 'Опис',
            icon: 'pi pi-comment',
            command: () => {
                setTabShowed(1);
            },
        },
        ...(formik.values?.id
            ? [
                {
                    label: 'Історія',
                    icon: 'pi pi-history',
                    command: () => {
                        setTabShowed(2);
                        getServiceHistory(formik.values?.id, historyLazyState);
                    },
                },
            ]
            : []),
    ];

    const ReturnButtonHandler = () => {
        navigate("/services");
    }

    const mainToolbarLeftTemplate = () => {
        return (<h3>Створення послуги</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />
                <Button label="Вийти" severity="secondary" type="button" className={commonStyle.closeButton} onClick={ReturnButtonHandler} />
            </div>
        );
    }

    return (<>
        <form onSubmit={formik.handleSubmit}>
            <Toast ref={toast}/>
            <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
            <div className="grid nested-grid">
                <div className={`${commonStyle.leftMenu} col-3`}>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <TreeSelect
                                    name="service_group"
                                    value={formik.values.service_group}
                                    onChange={DropdownChangeHandler}
                                    options={sortedGroups}
                                    showClear
                                    className={formik.errors.service_group && formik.touched.service_group ? 'p-invalid w-full' : 'w-full'} />
                                <label>Назва групи</label>
                            </FloatLabel>
                            {formik.errors.service_group && formik.touched.service_group && (<small className={commonStyle.errorSmall}>{formik.errors.service_group}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText name="name" value={formik.values.name}
                                    onChange={(e) => { formik.setFieldValue('name', e.target.value); }}
                                    className={formik.errors.name && formik.touched.name ? 'p-invalid w-full' : 'w-full'} />
                                <label>Назва послуги</label>
                            </FloatLabel>
                            {formik.errors.name && formik.touched.name && (<small className={commonStyle.errorSmall}>{formik.errors.name}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="service_article"
                                    value={formik.values.service_article}
                                    onChange={(e) => { formik.setFieldValue('service_article', e.target.value) }}
                                />
                                <label>Артикул</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-1">
                            <span className={`${commonStyle.currencySpan} p-inputgroup-addon`}>
                                <Dropdown className={`${commonStyle.currencyDropDown} w-full`} value={formik.values.currency} onChange={(e) => formik.setFieldValue('currency', e.value)} options={systemCurrencies} />
                            </span>
                            <FloatLabel>
                                <InputText
                                    name="price"
                                    value={formik.values.price}
                                    onChange={(e) => { formik.setFieldValue('price', e.target.value) }}
                                    className={formik.errors.price && formik.touched.price ? 'p-invalid' : ''}
                                />
                                <label>Ціна</label>
                            </FloatLabel>
                            {formik.errors.price && formik.touched.price && (<small className={commonStyle.errorSmall}>{formik.errors.price}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-1">
                            <FloatLabel>
                                <Dropdown name="status" value={formik.values.is_active} onChange={(e) => formik.setFieldValue('is_active', e.value)} options={statusList} showClear className="w-full" />
                                <label>Статус</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
                <div className="col-9">
                    <TabMenu model={items} />
                    {tabShowed === 0 && <div className="grid">
                        {customfields != null && (customfields.map((field) => (
                            <div className="col-4" key={field.id}>
                                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                                    <FloatLabel>
                                        <InputText
                                            name={`service_fields_values.${field.id}`}
                                            value={formik.values.service_fields_values[field.id] || ""}
                                            onChange={(e) => { formik.setFieldValue(`service_fields_values.${field.id}`, e.target.value) }}
                                        />
                                        <label>{field.name}</label>
                                    </FloatLabel>
                                </div>
                            </div>
                        )))}
                    </div>}
                    {tabShowed === 1 && <InputTextarea className={commonStyle.fullWidth} value={formik.values.notes} onChange={(e) => { formik.setFieldValue('notes', e.target.value) }} rows={10} />}
                    {tabShowed === 2 && <HistoryElement data={serviceHistory} lazyState={historyLazyState} totalRecords={historyTotalRecords} onHistoryPage={onHistoryPage} isAct={false}/>}
                </div>
            </div>
        </form>
    </>
    );
}

export default ServiceCreation;