import { buildGroupHierarchy, findGroupCode, statusList, systemCurrencies } from "../../../../Utils/Utils";
import EnumsContext from "../../../../context/enums-context";
import useApi from "../../../../Utils/BackendClient";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect, useRef } from "react";
import useGetEnums from "../../../../Utils/EnumsUtils";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toolbar } from "primereact/toolbar";
import { TreeSelect } from "primereact/treeselect";

import './ProductCreation.module.css';
import commonStyle from "../../CommonStyles.module.css";
import { TabMenu } from "primereact/tabmenu";
import { Toast } from "primereact/toast";
import HistoryElement from "../../../UIElements/HistoryElement";

const ProductCreation = () => {

    const toast = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const ctx = useContext(EnumsContext);
    const { createProduct, fetchProductsExtended, fetchProductDetails, fetchProductGroupDetails, fetchProductHistory, updateProduct } = useApi();

    const [customfields, setCustomFields] = useState([]);
    const [sortedGroups, setSortedGroups] = useState([]);
    const [productHistory, setProductHistory] = useState([]);
    const [historyTotalRecords, setHistoryTotalRecords] = useState(0);
    const [isEdit, setIsEdit] = useState(false);
    const [tabShowed, setTabShowed] = useState(0);
    const [product, setProduct] = useState({
        product_name: "",
        product_group: null,
        product_article: "",
        currency: "UAH",
        price: "",
        is_active: "Активний",
        notes: "",
        product_fields_values: {}
    });
    const [historyLazyState, setHistoryLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1   
    });

    useGetEnums(["product_groups"]);

    useEffect(() => {

        const getGroupsExtended = async () => {

            const response = await fetchProductsExtended();

            if (response.status === 200) {

                const groups = buildGroupHierarchy(response.data);
                setSortedGroups(groups);

                if (location.state.id !== undefined) {
                    setIsEdit(true);

                    const fetchData = async () => {

                        const response = await fetchProductDetails(location.state.id);

                        if (response.status === 200) {
                            response.data.product_group = findGroupCode(groups, response.data.product_group);
                            response.data.is_active = response.data.is_active ? "Активний" : "Деактивований";

                            let fields = [];
                            let productFields = {};

                            response.data.product_fields_values.forEach(element => {
                                const field = {
                                    id: element.product_group_field,
                                    field_id: element.id,
                                    field_name: element.field_name,
                                    field_type: element.field_type
                                };

                                fields = [...fields, field];
                                productFields[element.product_group_field] = element.value;
                            });

                            response.data.product_fields_values = productFields;

                            setProduct(response.data);
                            setCustomFields(fields);
                        }
                    };

                    fetchData();
                }
            }
        }

        getGroupsExtended();

    }, [location.state, ctx.product_groups, fetchProductDetails, fetchProductsExtended]);

    const formik = useFormik({
        initialValues: product,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (!values.product_name) {
                errors.product_name = "Назва продукту не повинна бути пустою";
            }

            if (!values.product_group) {
                errors.product_group = "Назва групи не повинна бути пустою";
            }

            if (!values.price) {
                errors.price = "Введіть ціну товару";
            }

            return errors;
        },
        onSubmit: values => {

            if (values.product_group.toString().includes('-')) {
                var keys = values.product_group.split('-');
                values.product_group = keys[(keys.length - 1)];
            }

            values.is_active = values.is_active === "Активний";

            let fields = [];

            Object.entries(values.product_fields_values).forEach(([key, value]) => {
                const field = {
                    product_group_field: key,
                    value: value,
                }

                if (location.state != null) {
                    const element = customfields.find(element => element.id === Number(key));
                    field.id = element.field_id;
                }

                fields = [
                    ...fields,
                    field
                ]

            });

            values.product_fields_values = fields;

            const fetchData = async () => {

                const response = location.state.id === undefined ? await createProduct(values) : await updateProduct(location.state.id, values);

                const successStatus = isEdit ? 200 : 201;

                let toastMessage = {
                    severity: "error", summary: isEdit ? "Помилка редагування" : "Помилка створення",
                    detail: "Товар не " + (isEdit ? "відредаговано. " : " створено. ") + "Помилка сервера " + response.status, life: 3000
                };

                if (response.status === successStatus) {
                    toastMessage = location.state.id === undefined ? { severity: "success", summary: "Успіх створення", detail: "Товар " + values.product_name + " успішно створено", life: 3000 } :
                        { severity: "success", summary: "Успіх редагування", detail: "Товар " + values.product_name + " успішно відредаговано", life: 3000 }
                }

                navigate("/products", { state: { toast: toastMessage, productPageState: location.state.productPageState } });
            };

            fetchData();
        }
    });

    const DropdownChangeHandler = async e => {
        let product_group_code = e.value;

        let productGroupId = product_group_code;

        if (product_group_code !== null && product_group_code !== undefined) {
            if (product_group_code.toString().includes('-')) {
                var keys = product_group_code.split('-');
                productGroupId = keys[(keys.length - 1)];
            }
        }
        else {
            product_group_code = null;
        }

        formik.setFieldValue('product_group', product_group_code);

        if (productGroupId) {

            const fetchData = async () => {

                const response = await fetchProductGroupDetails(productGroupId);

                if (response.status === 200) {

                    const fields = response.data.fields;

                    setCustomFields(fields);

                    const transformedObject = fields.reduce((acc, field) => {
                        acc[field.id] = "";
                        return acc;
                    }, {});

                    formik.setFieldValue('product_fields_values', transformedObject);
                }
                else {
                    setCustomFields([]);
                }
            };

            fetchData();
        }
        else {
            setCustomFields([]);
        }
    };

    const getProductHistory = async (id, state) => {
        
        const response = await fetchProductHistory(id, state);

        if(response.status === 200){
            setProductHistory(response.data.results);
            setHistoryTotalRecords(response.data.count);
        }
        else{
            toast.current.show({ severity: "error", summary: "Помилка отримання історії", detail: "Помилка сервера " + response.status, life: 3000 });
        }
    }

    const onHistoryPage = (event) => {
        event.page = event.page + 1;
        getProductHistory(formik.values?.id, event);

        setHistoryLazyState(event);
    }

    const items = [
        {
            label: 'Налаштуванна полів',
            icon: 'pi pi-book',
            command: () => {
                setTabShowed(0);
            },
        },
        {
            label: 'Опис',
            icon: 'pi pi-comment',
            command: () => {
                setTabShowed(1);
            },
        },
        ...(isEdit
            ? [
                {
                    label: 'Історія',
                    icon: 'pi pi-history',
                    command: () => {
                        setTabShowed(2);
                        getProductHistory(formik.values?.id, historyLazyState);
                    },
                },
            ]
            : []),
    ];

    const SaveButtonHandler = () => {
        navigate('/products', { state: { productPageState: location.state.productPageState } });
    };

    const Upload = () => { };

    const mainToolbarLeftTemplate = () => {
        if(isEdit){
            return (<h3>Редагування товару "{formik.values.product_name}"</h3>); 
        }
        return (<h3>Створення товару</h3>);
    };

    const mainToolbarRightTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />
                <Button label="Вийти" severity="secondary" className={commonStyle.closeButton} type="button" onClick={SaveButtonHandler} />
            </div>
        );
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Toast ref={toast}/>
            <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
            <div className="grid nested-grid">
                <div className="leftMenu col-3">
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <TreeSelect
                                    name="product_group"
                                    value={formik.values.product_group}
                                    onChange={DropdownChangeHandler}
                                    options={sortedGroups}
                                    showClear
                                    className={formik.errors.product_group && formik.touched.product_group ? 'p-invalid w-full' : 'w-full'} />
                                <label>Назва групи</label>
                            </FloatLabel>
                            {formik.errors.product_group && formik.touched.product_group && (<small className={commonStyle.errorSmall}>{formik.errors.product_group}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="product_name"
                                    value={formik.values.product_name}
                                    onChange={e => formik.setFieldValue('product_name', e.target.value)}
                                    className={formik.errors.product_name && formik.touched.product_name ? 'p-invalid' : ''}
                                />
                                <label>Назва товару</label>
                            </FloatLabel>
                            {formik.errors.product_name && formik.touched.product_name && (<small className={commonStyle.errorSmall}>{formik.errors.product_name}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="product_article"
                                    value={formik.values.product_article}
                                    onChange={e => formik.setFieldValue('product_article', e.target.value)}
                                />
                                <label>Артикул</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className={`${commonStyle.formInput}`}>
                        <div className="p-inputgroup py-0 flex-1">
                            <span className="currencySpan p-inputgroup-addon">
                                <Dropdown
                                    className="currencyDropDown"
                                    value={formik.values.currency}
                                    onChange={e => formik.setFieldValue('currency', e.value)}
                                    options={systemCurrencies} />
                            </span>
                            <FloatLabel>
                                <InputText
                                    name="price"
                                    value={formik.values.price}
                                    onChange={e => formik.setFieldValue('price', e.target.value)}
                                    className={formik.errors.price && formik.touched.price ? 'p-invalid' : ''}
                                />
                                <label>Ціна</label>
                            </FloatLabel>
                            {formik.errors.price && formik.touched.price && (<small className={commonStyle.errorSmall}>{formik.errors.price}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-1">
                            <FloatLabel>
                                <Dropdown name="status" value={formik.values.is_active} onChange={e => formik.setFieldValue('is_active', e.value)} options={statusList} showClear className="w-full" />
                                <label>Статус</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div>
                        <label>Завантажити фото</label>
                        <FileUpload
                            mode="basic"
                            name="demo[]"
                            accept="image/*"
                            maxFileSize={1000000}
                            customUpload
                            uploadHandler={Upload}
                        >
                        </FileUpload>
                    </div>
                </div>
                <div className="col-9">
                    <TabMenu model={items} />
                    {tabShowed === 0 &&
                        <div className="grid">
                            {customfields.length > 0 && (customfields.map((field) => (
                                <div className="col-4" key={field.id}>
                                    <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                                        <FloatLabel>
                                            <InputText
                                                name={`product_fields_values.${field.id}`}
                                                value={formik.values.product_fields_values[field.id] || ""}
                                                onChange={e => formik.setFieldValue(`product_fields_values.${field.id}`, e.target.value)}
                                            />
                                            <label>{field.field_name}</label>
                                        </FloatLabel>
                                    </div>
                                </div>
                            )))}
                        </div>}
                    {tabShowed === 1 && <div className="tableStyle">
                        <InputTextarea name="notes" value={formik.values.notes} onChange={e => formik.setFieldValue('notes', e.target.value)} className={commonStyle.fullWidth} rows={10} />
                    </div>}
                    {tabShowed === 2 && <HistoryElement data={productHistory} lazyState={historyLazyState} totalRecords={historyTotalRecords} onHistoryPage={onHistoryPage} isAct={false}/>}
                </div>
            </div>
        </form>
    );
}

export default ProductCreation;