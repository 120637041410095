import { DataTable } from "primereact/datatable";
import { Translate } from "../../Utils/TranslationUtils";
import { formatDate, formatHistoryChanges } from "../../Utils/Utils";
import { Column } from "primereact/column";

const HistoryElement = ({data, lazyState, totalRecords, onHistoryPage, isAct}) => {
    return (
        <DataTable value={data} emptyMessage="Історії не знайдено"
            lazy paginator onPage={onHistoryPage} first={lazyState.first} totalRecords={totalRecords} rows={lazyState.rows} rowsPerPageOptions={[10, 20, 50]}>
            <Column field="action_type" header="Тип змін" body={(rowData) => Translate(rowData.action_type)} />
            <Column field="changes" header="Зміни" body={(rowData) => formatHistoryChanges(rowData.action_type, rowData.changes, isAct)} />
            <Column field='action_time' header="Час змін" body={(rowData) => formatDate(rowData.action_time)} />
            <Column field="made_by" header="Користувач" />
        </DataTable>);
}

export default HistoryElement;