import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Sidebar } from "primereact/sidebar";
import { useEffect, useState } from "react";
import { formatShortDate, systemCurrencies, systemPaymentMethods } from "../../Utils/Utils";
import commonStyle from "../content/CommonStyles.module.css"; 
import useApi from "../../Utils/BackendClient";
import Cookies from "js-cookie";

const PaymentSideBar = ({ visible, onHide, onMessage, data, moneyMovementType, reloadData}) => {

    const { createPayment, fetchActUnpaidBalance } = useApi();

    const [unDividedSum, setUnDividedSum] = useState(0);
    const [totalSumUAH, setTotalSumUAH] = useState(0);
    const [paymentData, setPaymentData] = useState(
        {
            id: null,
            cagent_id: null,
            payments: []
        }
    )

    const formik = useFormik({
        initialValues: paymentData,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            values.payments.forEach((payment, index) => {
                if (payment.paymentMethod === null || payment.paymentMethod === '') {
                    if (!errors.payments) errors.payments = [];
                    errors.payments[index] = { paymentMethod: 'Вкажіть спосіб оплати' };
                }
            });

            return errors
        },
        onSubmit: values => {

            let paymentRequest = [];

            values.payments.forEach(payment => {

               const singlePaymentRequest = {
                  act_id: values.id,
                  date: formatShortDate(Date.now()),
                  sum: payment.sum,
                  currency: payment.currency,
                  type: systemPaymentMethods[payment.paymentMethod],
                  method: moneyMovementType,
                  cagent_id: values.cagent_id != null ? values.cagent_id : null,
               }

               paymentRequest.push(singlePaymentRequest);
            });

            const callBackEnd = async () => {

                const response = await createPayment(paymentRequest);

                if (response.status === 201) {
                    onMessage({severity: "success", summary: 'Оплата', detail: 'Оплата пройшла успішно', life: 2000});
                    reloadData();
                    onHide();
                }
                else if (response.status === 403) {
                    onMessage({ severity: "error", summary: "Помилка продажу", detail: "Користувач не має прав проводити оплату", life: 3000 });
                }
                else {
                    onMessage({ severity: "error", summary: "Помилка продажу", detail: "Помилка створення платежу у валюті, код помилки:" + response.status, life: 3000 });
                }
            }

            callBackEnd();
        }
    });

    useEffect(() => {

        if (data !== null) {
            const fetchActDetails = async () => {
                const response = await fetchActUnpaidBalance(data.id);

                if (response.status === 200) {

                    let paymentObject = {};
                    paymentObject.id = data.id;
                    paymentObject.cagent_id = data.cagent;

                    let amountUAH = 0;

                    const paymentsToPass = [];
                    response.data.forEach(payment => {
                        if (payment.sum !== 0) {
                            amountUAH += payment.sum * (payment.currency === "UAH" ? 1 : Cookies.get(`${payment.currency}Rate`));
                            payment.paymentMethod = null;

                            paymentsToPass.push(payment);
                        }
                    });
                    paymentObject.payments = paymentsToPass;
                    setPaymentData(paymentObject);
                    setTotalSumUAH(amountUAH);
                }
                else {
                    onMessage({ severity: "error", summary: 'Помилка отримання даних', detail: `Помилка отримання даних акту ${data.id}`, life: 3000 });
                }
            }

            fetchActDetails();
        }
    }, [data, fetchActUnpaidBalance, onMessage]);

    useEffect(() => {
        let convertedAmount = 0;
  
        if (formik.values.payments.length !== 0) {
           formik.values.payments.forEach(payment => {
              convertedAmount += payment.sum * (payment.currency === "UAH" ? 1 : Cookies.get(`${payment.currency}Rate`));
           });
        }
        
        setUnDividedSum(totalSumUAH - convertedAmount);
  
    }, [formik.values.payments, formik.values.discount, totalSumUAH]);

    const addPaymentToFormik = () => {
        const newPayment = [
           ...formik.values.payments,
           { currency: "UAH", sum: 0.00, paymentMethod: null }
        ];
        formik.setFieldValue('payments', newPayment);
    };

    const handlePaymentsAmountChange = (index, newAmount) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].sum = newAmount;
        formik.setFieldValue('payments', newPayments);
    };

    const handlePaymentsCurrencyChange = (index, newCurrency) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].currency = newCurrency;
        formik.setFieldValue('payments', newPayments);
    };

    const handlePaymentMethodChange = (index, value) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].paymentMethod = value;
        formik.setFieldValue('payments', newPayments);
    };

    const deletePayment = (index) => {
        console.log(index);
        const newPayments = [...formik.values.payments];
        newPayments.splice(index, 1);
        formik.setFieldValue('payments', newPayments);
    };

    return (<Sidebar className="responsive-sidebar" header="Оплата товару" visible={visible} position="right" onHide={onHide}>
        <form onSubmit={formik.handleSubmit}>
            {formik.values.payments.length === 0 && (
                <div className="mb-2">
                    <Message className='sideBarMessage' severity="error" text="Додайте валюту і спосіб оплати" />
                </div>
            )}
            {formik.values.payments.map((currencyAmount, index) => (
                <div className="grid py-3" key={index}>
                    <div className="col-10 p-inputgroup py-0 flex-1">
                        <span className={`${commonStyle.currencySpan} p-inputgroup-addon`}>
                            <Dropdown
                                className={commonStyle.currencyDropDown}
                                value={currencyAmount.currency}
                                onChange={e => handlePaymentsCurrencyChange(index, e.value)}
                                options={systemCurrencies.map(currency => ({ label: currency, value: currency }))}
                            />
                        </span>
                        <InputText
                            name={`currencyValuePair[${index}].amount`}
                            value={currencyAmount.sum}
                            onChange={e => handlePaymentsAmountChange(index, e.target.value)}
                        />
                    </div>
                    <div className="col-2">
                        <Button className="noStyleButton" icon="pi pi-trash" severity="danger" type='button' text onClick={() => deletePayment(index)} />
                    </div>
                    <div className="col-10 py-0">
                        <Dropdown
                            className={formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index] ? `${commonStyle.fullWidth} p-invalid` : `${commonStyle.fullWidth}`}
                            value={currencyAmount.paymentMethod}
                            onChange={e => handlePaymentMethodChange(index, e.value)}
                            options={Object.keys(systemPaymentMethods)}
                            placeholder="Спосіб оплати"
                        />
                        {formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index] && (
                            <small className={commonStyle.errorSmall}>{formik.errors.payments[index].paymentMethod}</small>
                        )}
                    </div>
                </div>
            ))}
            <div className='mb-2'>
                <Message className={commonStyle.fullWidth} severity={unDividedSum > 0 ? "warn" : "success"} text={`Нерозподілена сума: ${unDividedSum} грн.`} />
            </div>
            <div>
                <Button label="+ Додати валюту" severity="info" type="button" className={`${commonStyle.fullWidth} ${commonStyle.editButton}`} onClick={addPaymentToFormik} />
                {formik.values.payments.length !== 0 && <Button label="Оплатити" severity="success" type="submit" className={`${commonStyle.fullWidth} ${commonStyle.addButton} mt-1`} />}
            </div>
        </form>
    </Sidebar>);
}

export default PaymentSideBar;