import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useState, useEffect } from "react";
import useApi from "../../../../Utils/BackendClient";
import { formatCurrency, systemCurrencies } from "../../../../Utils/Utils";

const Settlements = () => {
    const { fetchSettlements } = useApi();
    const [expandedRows, setExpandedRows] = useState([])
    const [settlements, setSettlements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "name",
        sortOrder: 1,
    });

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchSettlements(lazyState);
            if (response.status === 200) {
                setTotalRecords(response.data.count);
                setSettlements(response.data.results);
            }
            setLoading(false);
        };
        fetchData();
    }, [lazyState, fetchSettlements]);

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const expandedRowTemplate = (rowData) => {
        const data = [];
        systemCurrencies.forEach(currency => {
            let currencyData = {};
            currencyData.currency = currency;
            currencyData.receivable = formatCurrency(rowData.receivable_by_currency.find(x => x.currency === currency)?.sum ?? 0, currency);
            currencyData.overdue_receivable = formatCurrency(rowData.overdue_receivable_by_currency.find(x => x.currency === currency)?.sum ?? 0, currency);
            currencyData.payable = formatCurrency(rowData.payable_by_currency.find(x => x.currency === currency)?.sum ?? 0, currency);
            currencyData.overdue_payable = formatCurrency(rowData.overdue_payable_by_currency.find(x => x.currency === currency)?.sum ?? 0, currency);

            data.push(currencyData);
        });

        return (
            <DataTable value={data}>
                <Column style={{ width: '5%' }} headerStyle={{ display: 'none' }}/>
                <Column field="currency" style={{ width: '15%' }} headerStyle={{ display: 'none' }}/>
                <Column field="receivable" header="Дебіторська заборгованість" body={(data) => <span style={{ color: "#03C04A"}}>{data.receivable}</span>} 
                        style={{ width: '20%' }} headerStyle={{ display: 'none' }}/>
                <Column field="overdue_receivable" header="(в тому числі прострочена)" body={(data) => <span style={{ color: "#03C04A"}}>{data.overdue_receivable}</span>} 
                        style={{ width: '20%' }} headerStyle={{ display: 'none' }}/>
                <Column field="payable_UAH" header="Кредиторська заборгованість" body={(data) => <span style={{ color: "#EF4444"}}>{data.payable}</span>} 
                        style={{ width: '20%' }} headerStyle={{ display: 'none' }}/>
                <Column field="payable_UAH" header="(в тому числі прострочена)" body={(data) => <span style={{ color: "#EF4444"}}>{data.overdue_payable}</span>} 
                        style={{ width: '20%' }} headerStyle={{ display: 'none' }}/>
            </DataTable>
        );
    };

    return (
        <div className="grid mt-3">
            <div className="col-12 justify-content-center">
                <DataTable
                    value={settlements}
                    lazy
                    rows={lazyState.rows}
                    paginator
                    onPage={onPage}
                    first={lazyState.first}
                    loading={loading}
                    totalRecords={totalRecords}
                    onSort={onSort}
                    sortField={lazyState.sortField} 
                    sortOrder={lazyState.sortOrder}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    expandedRows={expandedRows}
                    rowExpansionTemplate={expandedRowTemplate} // Add this for expandable rows
                    rowsPerPageOptions={[10, 20, 50]}
                >
                    <Column expander style={{ width: '5%' }}/>
                    <Column field="name" header="Назва" sortable style={{ width: '15%' }}/>
                    <Column field="receivable_UAH" header="Дебіторська заборгованість" sortable body={(rowData) => <span style={{ color: "#03C04A"}}>{formatCurrency(rowData.receivable_UAH, "UAH")}</span>} style={{ width: '20%' }}/>
                    <Column field="overdue_receivable_UAH" header="(в тому числі прострочена)" sortable body={(rowData) => <span style={{ color: "#03C04A"}}>{formatCurrency(rowData.overdue_receivable_UAH, "UAH")}</span>} style={{ width: '20%' }}/>
                    <Column field="payable_UAH" header="Кредиторська заборгованість" sortable body={(rowData) => <span style={{ color: "#EF4444"}}>{formatCurrency(rowData.payable_UAH, "UAH")}</span>} style={{ width: '20%' }}/>
                    <Column field="overdue_payable_UAH" header="(в тому числі прострочена)" sortable body={(rowData) => <span style={{ color: "#EF4444"}}>{formatCurrency(rowData.oerdue_payable_UAH, "UAH")}</span>} style={{ width: '20%' }}/>
                </DataTable>
            </div>
        </div>
    );
};

export default Settlements;