import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState} from "react";
import EnumsContext from "../../../../context/enums-context";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { findKeyByValue, statusList } from "../../../../Utils/Utils";
import useApi from "../../../../Utils/BackendClient";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { TabMenu } from "primereact/tabmenu";
import { Toolbar } from "primereact/toolbar";

import commonStyle from "../../CommonStyles.module.css";
import HistoryElement from "../../../UIElements/HistoryElement";
import { Toast } from "primereact/toast";

const NewDivision = () => {

    const countries = ["Україна"];
    const navigate = useNavigate();
    const location = useLocation();
    const ctx = useContext(EnumsContext);
    const toast = useRef(null);
    const { createDivision, fetchDivisionDetails, fetchDivisionHistory, updateDivision } = useApi();

    const [tabShowed, setTabShowed] = useState(0);
    const [history, setHistory] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [totalHistoryRecords, setHistoryTotalRecords] = useState(0);
    const [historyLazyState, setHistoryLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1   
    });
    const [division, setDivision] = useState(
        {
            name: "",
            is_active: "Активний",
            root_division: null,
            country: "Україна",
            region: null,
            city: "",
            street: "",
            building_number: "",
            apartment_number: "",
            notes: "",
        }
    ); 

    const menuItems = [
        {
            label: "Загальні дані",
            icon: 'pi pi-book',
            command: () => {
                setTabShowed(0);
            }

        },
        {
            label: "Адреса",
            icon: 'pi pi-home',
            command: () => {
                setTabShowed(1);
            }
        },
        ...(isEdit
            ? [
                {
                    label: 'Історія',
                    icon: 'pi pi-history',
                    command: () => {
                        setTabShowed(2);
                        getHistory(location.state.id, historyLazyState);
                    },
                },
            ]
            : []),
    ]

    useGetEnums(["divisions", "regions"]);

    useEffect(() => {
        if (location.state !== null) {

            setIsEdit(true);
            const fetchData = async () => {

                const response = await fetchDivisionDetails(location.state.id);

                response.data.is_active = response.data.is_active ? "Активний" : "Деактивований";
                response.data.root_division = ctx.divisions[response.data.root_division];
                response.data.country = "Україна";
                response.data.region = ctx.regions[response.data.region];

                setDivision(response.data);
            }

            fetchData();
        }
    }, [location.state, ctx.divisions, ctx.regions, fetchDivisionDetails]);

    const ReturnButtonHandler = () => {
        navigate("/divisions");
    }

    const formik = useFormik({
        initialValues: division,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.name === null || values.name === "") {
                errors.name = "Назва підрозділу не повинна бути пустою"
            }

            if (values.country === null || values.country === undefined) {
                errors.country = "Виберіть будь ласка країну"
            }

            if (values.region === null || values.region === undefined) {
                errors.region = "Виберіть будь ласка область"
            }
            return errors;
        },
        onSubmit: async (values) => {

            values.is_active = values.is_active === "Активний" ? true : false;

            if (values.root_division != null) {
                values.root_division = findKeyByValue(ctx.divisions, values.root_division);
            }
            values.country = "UKRAINE";

            values.region = findKeyByValue(ctx.regions, values.region);

            const response = !isEdit ? await createDivision(values) : await updateDivision(location.state.id, values);

            let toastMessage = {
                severity: "error", summary: isEdit ? "Помилка редагування" : "Помилка створення",
                detail: "Підрозділ " + values.name + " не " + (isEdit ? "відредаговано. " : " створено. ") + " Код помилки: " + response.status + " Помилка: " + response.data.detail, life: 3000
            };

            if (isEdit && response.status === 200) {
                toastMessage = { severity: "success", summary: "Успіх редагування", detail: "Підрозділ " + values.name + " успішно відредаговано", life: 3000 }
            }
            else if (!isEdit && response.status === 201) {
                toastMessage = { severity: "success", summary: "Успіх створення", detail: "Підрозділ " + values.name + " успішно створено", life: 3000 }
            }

            navigate("/divisions", { state: { toast: toastMessage } });
        }
    });

    const getHistory = async (id, state) => {

        const response = await fetchDivisionHistory(id, state);

        console.log(response);

        if (response.status === 200) {
            setHistory(response.data.results);
            setHistoryTotalRecords(response.data.count);
        }
        else {
            toast.current.show({ severity: "error", summary: "Помилка отримання історії", detail: "Помилка сервера " + response.status, life: 3000 });
        }
    }

    const onHistoryPage = (event) => {
        event.page = event.page + 1;
        getHistory(location.state.id, event);

        setHistoryLazyState(event);
    }

    const mainToolbarLeftTemplate = () => {
        if(isEdit){
            return (<h3>Редагування підрозділу "{formik.values.name}" </h3>);
        }
        return (<h3>Створення нового підрозділу</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (<>
            <div className="flex flex-wrap gap-2">
                <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />
                <Button label="Вийти" severity="secondary" type="button" className={commonStyle.closeButton} onClick={ReturnButtonHandler} />
            </div>
        </>);
    }

    return (<form onSubmit={formik.handleSubmit}>
        <Toast ref={toast}/>
        <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
        <TabMenu model={menuItems} />
        {tabShowed === 0 && <div className="grid">
            <div className="col-4">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText
                            name="name"
                            value={formik.values.name}
                            onChange={(e) => { formik.setFieldValue('name', e.target.value) }}
                            className={formik.errors.name && formik.touched.name ? 'p-invalid' : ''}
                        />
                        <label>Назва підрозділу</label>
                    </FloatLabel>
                    {formik.errors.name && formik.touched.name && (<small className={commonStyle.errorSmall}>{formik.errors.name}</small>)}
                </div>
            </div>
            <div className="col-4">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <Dropdown name="is_active" value={formik.values.is_active}
                            onChange={(e) => formik.setFieldValue('is_active', e.value)}
                            options={statusList}
                            showClear />
                        <label>Статус</label>
                    </FloatLabel>
                </div>
            </div>
            <div className="col-4">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <Dropdown name="root_division" value={formik.values.root_division}
                            onChange={(e) => formik.setFieldValue('root_division', e.value)}
                            options={Object.values(ctx.divisions)}
                            showClear />
                        <label>Батьківський підрозділ</label>
                    </FloatLabel>
                </div>
            </div>
        </div>}
        {tabShowed === 1 && <><div className="grid">
            <div className="col-4">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <Dropdown name="country" value={formik.values.country}
                            onChange={(e) => formik.setFieldValue('country', e.value)}
                            options={countries}
                            className={formik.errors.country ? 'p-invalid' : ''}
                            showClear />
                        <label>Країна</label>
                    </FloatLabel>
                    {formik.errors.country && (<small className={commonStyle.errorSmall}>{formik.errors.country}</small>)}
                </div>
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText value={formik.values.street} onChange={(e) => { formik.setFieldValue('street', e.target.value) }} />
                        <label>Вулиця</label>
                    </FloatLabel>
                </div>
            </div>
            <div className="col-4">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <Dropdown name="region" value={formik.values.region}
                            onChange={(e) => formik.setFieldValue('region', e.value)}
                            options={Object.values(ctx.regions)}
                            className={formik.errors.region && formik.touched.region ? 'p-invalid' : ''}
                            showClear />
                        <label>Область</label>
                    </FloatLabel>
                    {formik.errors.region && formik.touched.region && (<small className={commonStyle.errorSmall}>{formik.errors.region}</small>)}
                </div>
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText value={formik.values.building_number} onChange={(e) => { formik.setFieldValue('building_number', e.target.value) }} />
                        <label>Номер будинку</label>
                    </FloatLabel>
                </div>
            </div>
            <div className="col-4">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText value={formik.values.city}
                            onChange={(e) => { formik.setFieldValue('city', e.target.value) }}
                            className={formik.errors.city && formik.touched.city ? 'p-invalid' : ''} />
                        <label>Населений пункт</label>
                    </FloatLabel>
                    {formik.errors.city && formik.touched.city && (<small className={commonStyle.errorSmall}>{formik.errors.city}</small>)}
                </div>
                <div className={`${commonStyle.inputfields} p-inputgroup`}>
                    <FloatLabel>
                        <InputText value={formik.values.apartment_number} onChange={(e) => { formik.setFieldValue('apartment_number', e.target.value) }} />
                        <label>Квартира</label>
                    </FloatLabel>
                </div>
            </div>
        </div>
            <div className={`${commonStyle.inputfields} p-inputgroup col-8`}>
                <span className="p-float-label md:w-90">
                    <InputTextarea value={formik.values.description} onChange={(e) => { formik.setFieldValue('description', e.target.value) }} rows={10} cols={80} />
                    <label>Примітки</label>
                </span>
            </div>
        </>}
        {tabShowed === 2 && <HistoryElement data={history} lazyState={historyLazyState} totalRecords={totalHistoryRecords} onHistoryPage={onHistoryPage} isAct={false}/>}
    </form>)
}

export default NewDivision;