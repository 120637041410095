import { Sidebar } from "primereact/sidebar";
import commonStyle from "../content/CommonStyles.module.css";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { useFormik } from "formik";
import useApi from "../../Utils/BackendClient";
import { InputNumber } from "primereact/inputnumber";
import { useEffect, useState } from "react";

const StickerPrintSidebar = ({ visible, onHide, data }) => {

    const { fetchRemainingQRCode } = useApi();

useEffect(() => {

    if (data !== null) {
        
        const array = [];
        data.forEach(element => {
            const object = {};
            object.product_name = element.product_name;
            object.remaining_id = element.id;
            object.accounting_type = element.identifier === undefined ? "PIECE" : "INDIVIDUAL"
            object.quantity = element.total_quantity;

            array.push(object);
        });

        setProductData(array);   
    }
}, [data]);

    const [productData, setProductData] = useState([]);

    const stickerFormik = useFormik({
        initialValues: {
            stickerData: productData
        },
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            return errors;
        },
        onSubmit: async (values) => {

            const response = await fetchRemainingQRCode(values.stickerData);

            if (response.status === 200) {
                console.log(response);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;

                link.download = `price_tags.pdf`;
                document.body.appendChild(link);
                link.click();

                // Clean up
                link.remove();
                window.URL.revokeObjectURL(url);

                onHide();
            }
        }
    });

    const DeleteSticker = rowData => {
        const stickers = [...stickerFormik.values.stickerData];
        const index = stickers.findIndex(sticker => sticker.remaining_id === rowData.remaining_id);
        stickers.splice(index, 1);
        stickerFormik.setFieldValue("stickerData", stickers);
    }

    const priceEditor = (options) => {
        return (
            <InputNumber
                inputStyle={{ width: '100%' }}
                value={options.value}
                onValueChange={(e) => {
                    const updatedData = [...stickerFormik.values.stickerData];
                    updatedData[options.rowIndex].quantity = e.value;
                    stickerFormik.setFieldValue("stickerData", updatedData);
                }}
                min={1}
            />
        );
    };

    const stickerBodyTemplate = (rowData) => {
        
        return (
            <Button
            icon="pi pi-trash"
            className={`p-button-rounded p-button-danger`}
            size="small"
            text
            tooltip="Видалити"
            tooltipOptions={{ position: "left" }}
            type="button"
            onClick={() => DeleteSticker(rowData)}
        />
        )
    }

    return (
        <Sidebar visible={visible} position="right" className={commonStyle.shortSideBar} onHide={onHide}>
            <form onSubmit={stickerFormik.handleSubmit}>
                <h2>Роздрук цінників на товари:</h2>
                <DataTable value={stickerFormik.values.stickerData} editMode="cell">
                    <Column field="product_name" style={{ width: '65%' }} />
                    <Column field="quantity" editor={(options) => priceEditor(options)} style={{ width: '15%' }} />
                    <Column field="action" align="right" body={stickerBodyTemplate} style={{ width: '20%' }} />
                </DataTable>
                <Button className={`${commonStyle.sideBarButton} ${commonStyle.addButton}`} label="Друкувати цінники" type="submit" />
            </form>
        </Sidebar>
    );
}

export default StickerPrintSidebar;