import ProductStatusCircle from "../../../UIElements/ProductStatusCircle";
import useApi from "../../../../Utils/BackendClient";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import commonStyle from "../../CommonStyles.module.css";

const Users = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { changeUserStatus, fetchUsersList } = useApi();
    const toast = useRef(null);

    const [users, setUsers] = useState([]);
    const [reloadData, setReloadData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "is_active",
        sortOrder: 1,
    });

    useEffect(() => {
        if (location.state !== null) {
            toast.current.show(location.state.toast);
        }
        location.state = null;
    }, [location.state, location]);


    useEffect(() => {

        const fetchData = async () => {

            const response = await fetchUsersList(lazyState);

            if (response.status !== 200) {
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку", detail: "Помилка сервера " + response.status, life: 3000 });
                setTotalRecords(0);
                setUsers([]);
            }
            else {

                setTotalRecords(response.data.count);
                setUsers(response.data.results);
            }

            setLoading(false);
        }

        fetchData();

    }, [reloadData, lazyState, fetchUsersList]);

    const ChangeUserStatusHandler = async (rowData) => {

        const action = rowData.is_active ? "deactivate_user" : "activate_user";

        const response = await changeUserStatus(rowData.id, action);
        //logging to remove later
        console.log(response);

        if (response.status !== 200) {
            toast.current.show({ severity: "error", summary: "Помилка зміни статусу", detail: "Помилка сервера " + response.status, life: 3000 });
        }
        else {
            toast.current.show({ severity: 'success', summary: rowData.is_active ? 'Деактивація' : "Активація", detail: rowData.is_active ? 'Користувача деактивовано' : "Користувача активовано", life: 3000 });
        }

        setReloadData(!reloadData);
    }

    const CreateNewUserButtonHandler = () => {
        navigate('/users/newuser');
    };

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const changeStatus = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: rowData.is_active ? 'Підтвердження деактивації користувача' : 'Підтвердження активації користувача',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => { ChangeUserStatusHandler(rowData) }
        });
    }

    const EditButtonHandler = (rowData) => {

        const route = '/users/edituser/' + rowData.id;
        navigate(route, { state: { id: rowData.id } });
    }

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати користувача" severity="success" className={commonStyle.addButton} onClick={CreateNewUserButtonHandler} />
        </div>)
    }

    const images = require.context('../../../../images', true);

    const getPicture = pictureName => {

        let path = './Random_user_pic.png';

        if (pictureName !== null) {
            path = './' + pictureName;
        }

        return images(path);
    }

    const userBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt={rowData.full_name} src={getPicture(null)} style={{ borderRadius: "50%" }} width="32" />
                <span>{rowData.full_name}</span>
            </div>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (<div className="flex flex-wrap gap-2">
            <Button
                icon={rowData.is_active ? "pi pi-times" : "pi pi-check"}
                className={`p-button-rounded ${rowData.is_active ? commonStyle.deleteButton + " p-button-danger" : commonStyle.addButton + " p-button-success"}`}
                tooltip={rowData.is_active ? "Деактивувати" : "Активувати"}
                onClick={(event) => changeStatus(event, rowData)}
            />
            <Button
                icon="pi pi-pencil"
                className={`p-button-rounded p-button-info ${commonStyle.editButton}`}
                tooltip="Редагувати"
                onClick={() => EditButtonHandler(rowData)}
            />
        </div>
        );
    };

    return (<>
        <Toast ref={toast} />
        <Toolbar className="mb-4" end={toolbarRightTemplate} />
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={users} lazy rows={lazyState.rows} paginator onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[5, 10, 20]} tableStyle={{ minWidth: '60rem' }} >
                    <Column field="full_name" header="Користувач" body={userBodyTemplate} />
                    <Column field="employee_position" header="Посада" />
                    <Column field="division_name" header="Підрозділ" />
                    <Column field="email" header="Email" sortable />
                    <Column field="is_active" header="Статус" body={rowData => <ProductStatusCircle rowData={rowData} type="statusCircle" />} sortable />
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
            <ConfirmPopup group="headless" />
        </div>
    </>)
}

export default Users;