import Cookies from "js-cookie";
import { Translate } from "./TranslationUtils";

export const systemCurrencies = ["UAH", "USD", "EUR"];
export const statusList = ["Активний", "Деактивований"];

export const systemPaymentMethods = {
    "Термінал": "CARD",
    "Готівка": "CASH",
    "IBAN": "IBAN"
};

export const findKeyByValue = (array, value) => {

    if (!Array.isArray(array)) {
        array = [array];
    }

    for (const member of array) {
        const entry = Object.entries(member).find(([key, val]) => val === value);
        if (entry) {
            return entry[0];
        }
    }
    return null; // Return null if the value is not found
};

export const convertSumWithLatestCurrencyRate = (currency, value) => {
    if (currency === "USD") {
        return value * Cookies.get("USDRate");
    }
    else if (currency === "EUR") {
        return value * Cookies.get("EURRate");
    }
    else {
        return value;
    }
}

export const formatCurrency = (value, currency) => {
    const locale = currency === "UAH" ? "ua-UA" : "en-EN";

    return value?.toLocaleString(locale, { style: 'currency', currency: currency });
};

export const formatShortDate = (unformattedDate) => {
    const date = new Date(unformattedDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

export const formatDate = (unformattedDate) => {
    const date = new Date(unformattedDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
}

export const buildGroupHierarchy = (data) => {

    const buildGroupHierarchy = (group, parentId = '') => {
        // Skip the group if it's not active
        if (group.is_active !== undefined && !group.is_active) {
            return null;
        }

        const newGroup = {
            key: parentId ? `${parentId}-${group.id}` : group.id,
            label: group.group_name,
            data: group.id,
            children: group.sub_groups && group.sub_groups.length > 0
                ? group.sub_groups.map(subgroup => buildGroupHierarchy(subgroup, `${parentId ? `${parentId}-${group.id}` : group.id}`)).filter(child => child !== null) // Filter out null children
                : null,
        };
        return newGroup;
    };

    // Filter out null groups before returning the result
    return data.map(group => buildGroupHierarchy(group)).filter(group => group !== null);
}

const formatValue = (value) => {
    if (value === null || value === undefined || value === "") {
        return "(пусто)";
    }
    if (typeof value === "boolean") {
        return value ? "true" : "false";
    }
    if (Array.isArray(value)) {
        if (value.length === 0) {
            return "(пусто)";
        }
        return value
            .map((item) =>
                Object.entries(item)
                    .map(([key, val]) => `${Translate(key)}: ${JSON.stringify(val)}`)
                    .join(", ")
            )
            .join("; ");
    }
    return Translate(value);
};

const formatArrayDifferences = (newArray, oldArray) => {
    if (!Array.isArray(newArray) || !Array.isArray(oldArray)) {
        return null;
    }

    // Find added elements
    const added = newArray.filter(
        (newItem) => !oldArray.some((oldItem) => oldItem.id === newItem.id)
    );

    // Find removed elements
    const removed = oldArray.filter(
        (oldItem) => !newArray.some((newItem) => newItem.id === oldItem.id)
    );

    // Find updated elements
    const updated = newArray
        .filter((newItem) =>
            oldArray.some(
                (oldItem) =>
                    oldItem.field_id === newItem.field_id &&
                    oldItem.value !== newItem.value // Check if value changed
            )
        )
        .map((newItem) => {
            const oldItem = oldArray.find(
                (oldItem) => oldItem.field_id === newItem.field_id
            );
            return {
                field_name: newItem.field_name,
                old_value: oldItem.value,
                new_value: newItem.value,
            };
        });

    const addedText = added
        .map((item) =>
            Object.entries(item)
                .filter(([key]) => key !== "id")
                .map(([key, value]) => `${Translate(key)}: ${JSON.stringify(value)}`)
                .join(", ")
        )
        .join("; ");

    const removedText = removed
        .map((item) =>
            Object.entries(item)
                .filter(([key]) => key.includes("name"))
                .map(([key, value]) => `${JSON.stringify(value)}`)
                .join(", ")
        )
        .join("; ");

    const updatedText = updated
        .map(
            (item) =>
                `${Translate(item.field_name)}: ${JSON.stringify(item.old_value)} -> ${JSON.stringify(item.new_value)}`
        )
        .join("; ");

    return (
        <div className="mt-2 mb-2">
            {added.length > 0 && (
                <div>
                    Додано: {addedText}
                </div>
            )}
            {removed.length > 0 && (
                <div>
                    Видалено: {removedText}
                </div>
            )}
            {updated.length > 0 && (
                <div>
                    Оновлено: {updatedText}
                </div>
            )}
        </div>
    );
};

const formatActArrayDifferences = (newArray, oldArray) => {

    const excludedKeys = ["id", "remaining_id", "return_sale_act", "status"];

    let added = [];
    let removed = [];

    newArray.forEach(element => {

        if(!oldArray.find(x => x.remaining_id === element.remaining_id))
        {
            added = [...added, element];
        }
    });

    oldArray.forEach(element => {
        if(!newArray.find(x => x.remaining_id === element.remaining_id))
        {
            removed = [...removed, element];
        }
    });

    const addedText = added
    .map((item) =>
        Object.entries(item)
            .filter(([key]) => !excludedKeys.includes(key))
            .map(([key, value]) => `${Translate(key)}: ${JSON.stringify(value)}`)
            .join(", ")
    )
    .join("; ");

    const removedText = removed
    .map((item) =>
        Object.entries(item)
            .filter(([key]) => !excludedKeys.includes(key))
            .map(([key, value]) => `${Translate(key)}: ${JSON.stringify(value)}`)
            .join(", ")
    )
    .join("; ");

    return (
        <div className="mt-2 mb-2">
            {added.length > 0 && (
                <div>
                    Додано: {addedText}
                </div>
            )}
            {removed.length > 0 && (
                <div>
                    Видалено: {removedText}
                </div>
            )}
        </div>
    );

    //will be added in future
}

export const formatHistoryChanges = (type, changes, isAct) => {

    return changes.map((change, index) => {
        const isArray =
            Array.isArray(change.new_value) && Array.isArray(change.old_value);

        return (
            <div key={index}>
                <strong>{Translate(change.field_name)}:{" "}</strong>
                {type === "UPDATE" ? (
                    isArray ? (
                        isAct ? formatActArrayDifferences(change.new_value, change.old_value) : formatArrayDifferences(change.new_value, change.old_value)
                    ) : (
                        `${formatValue(change.old_value)} -> ${formatValue(change.new_value)}`
                    )
                ) : (
                    `${formatValue(change.new_value)}`
                )}
            </div>
        );
    });
};

export const findGroupCode = (groups, targetId) => {
    // Recursive helper function to search through the hierarchy
    const search = (group, parentPath = '') => {
        const currentPath = parentPath ? `${parentPath}-${group.data}` : `${group.data}`;

        // Check if the current group's data matches the target ID
        if (group.data === targetId) {
            return currentPath;
        }

        // If the group has children, search within them
        if (group.children && group.children.length > 0) {
            for (let child of group.children) {
                const result = search(child, currentPath);
                if (result) {
                    return result;  // Return the full path if found
                }
            }
        }

        return null;  // Return null if not found
    };

    // Iterate over all top-level groups to start the search
    for (let group of groups) {
        const fullPath = search(group);
        if (fullPath) {
            return fullPath;  // Return the full path if found
        }
    }

    return null;  // Return null if the target ID was not found
};

export const getGroupCode = (fullId) => {

    if (fullId !== null && fullId?.toString().includes('-')) {
        const groups = fullId.split('-');
        return groups[groups.length - 1];
    }

    return fullId;
}